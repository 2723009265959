import { Component } from 'react';
import { MsalProvider, MsalContext } from '@azure/msal-react';
import './App.css';
import NavBar from './Components/Navigation/NavBar';
import LoginPage from 'Containers/LoginPage/Login';
import { Route, Switch, useLocation } from 'react-router-dom';
import PrivateRoute from './Controls/PrivateRoute';
import PegasusDashboardPage from './Containers/Dashboard/PegasusDashboard';
import { CustomNavigationClient } from './Utils/NavigationClient';
import Http404 from './Containers/HttpDefault/Http404';
import Quotation from 'Containers/Quotation';
import SuccessPage from 'Containers/HttpDefault/Http200';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import OverviewPage from 'Containers/OverviewPage';
import JobGrid from 'Containers/JobGrid';
import QuotationGrid from 'Containers/QuoteGrid';
import QuotationGridRecode from 'Containers/QuoteGrid/QuotationGridRecode';
import ServiceTemplate from 'Containers/ServiceTemplate';
import Port from 'Containers/PortSelection';
import Editor from 'Containers/PortEditor';
import QuotationList from 'Containers/QuotationList';
import { IFRAME_ROUTES } from 'Constants/Constant';
import Login from 'Containers/LoginPage/Login';

// export const history = createBrowserHistory();

export const Pages = () => {
  const location = useLocation();
  const renderNavbar = IFRAME_ROUTES.some(route => location.pathname.includes(route));
  return (
    <>
      {!renderNavbar && <NavBar />}
      <div>
        <Switch>
          <PrivateRoute exact key={location.key} path="/dashboard" component={PegasusDashboardPage} />
          <PrivateRoute exact key={location.key} path="/" component={QuotationList} />
          <PrivateRoute exact path="/quotation/create" component={Quotation} />
          <Route exact path="/overview/:id" render={(props) => (
            <OverviewPage id={props.match.params.id} />
          )} />
          <PrivateRoute exact path="/quotation-success" component={SuccessPage} />
          <PrivateRoute exact path="/job-services/:id" component={JobGrid} />
          <PrivateRoute exact path="/job-services/:id/:opId" component={JobGrid} />
          <PrivateRoute path="/quotation-services/:id" component={QuotationGridRecode} />
          <PrivateRoute exact path="/service-template/:id" component={ServiceTemplate} />
          <PrivateRoute exact path="/service-template" component={ServiceTemplate} />
          <PrivateRoute exact path="/port-editor/:id" component={Editor} />
          <PrivateRoute exact path="/port-selection" component={Port} />
          <PrivateRoute exact path="/port-selection/:id" component={Port} />
          <Route exact key={location.key} path="/page-not-found" component={Http404} />
          <Route exact path="/login" component={LoginPage} />
        </Switch>
        <ToastContainer autoClose={5000} />
      </div>
    </>
  );
}

class App extends Component<any> {
  static contextType = MsalContext;

  render() {
    const {
      history,
      pca,
    } = this.props
    const historyLocal = history;
    const context: any = this.context;
    const navigationClient = new CustomNavigationClient(historyLocal);
    const isSSOEnabled = process.env.REACT_APP_SSO_ENABLED;
    const isAuthenticated: any = context.accounts.length > 0;
    if (pca) {
      pca.setNavigationClient(navigationClient);
    }
    return (
      isSSOEnabled == "true" ? (
        <MsalProvider instance={pca}>
          {isAuthenticated
            ?
            <Login />
            :
            <Pages />}
        </MsalProvider>
      )
        :
        <Pages />
    )
  }
}

export default App;