
import JobGrid from './JobGrid'
import { connect } from 'react-redux';
import {
  getJobGridServices,
  addServices,
  deleteServices,
  addCharges,
  deleteCharges,
  onMultiDeleteServices,
  onUpdateService,
  onUpdateCharge,
  onSaveJobList,
  onUpdateServiceDetails,
  onBulkUpdate,
  onAddServiceTemplate,
  undoChanges,
  setAddServiceLoading,
  setAddChargeLoading,
  getVoucherList,
  onUpdateTariff,
  getExchangeRate,
  onUpdateEditService,
  updateChargeValidation
} from 'Redux/Actions/jobGrid';
import { getUserDetail } from 'Redux/Actions/User';
import { IJobDetails, IServicesAndCharges, ITemplate } from 'Model/Job/types';
import { IParams } from 'Model/Common/types';
import { IService } from 'Model/Job/JobService';
import { ICharge } from 'Model/Job/JobCharge';

const mapDispatchToProps = (dispatch: any) => ({
  getJobGridServices: (params: IParams) => dispatch(getJobGridServices(params)),
  undoChanges: (params: IParams) => dispatch(undoChanges(params)),
  addServices: () => dispatch(addServices()),
  deleteServices: (serviceIndex: number) => dispatch(deleteServices(serviceIndex)),
  addCharges: (serviceIndex: number) => dispatch(addCharges(serviceIndex)),
  setAddServiceLoading: (value: boolean) => dispatch(setAddServiceLoading(value)),
  setAddChargeLoading: (value: boolean) => dispatch(setAddChargeLoading(value)),
  deleteCharges: (servIndex: number, chargeIndex: number) => dispatch(deleteCharges(servIndex, chargeIndex)),
  onMultiDeleteServices: (list: number[]) => dispatch(onMultiDeleteServices(list)),
  onBulkUpdate: (services: IServicesAndCharges[]) => dispatch(onBulkUpdate(services)),
  onAddServiceTemplate: (services: ITemplate[]) => dispatch(onAddServiceTemplate(services)),
  onUpdateService: (service: IService, serviceIndex: number) =>
    dispatch(onUpdateService(service, serviceIndex)),
  onUpdateServiceDetails: (service: IService, serviceIndex: any) =>
    dispatch(onUpdateServiceDetails(service, serviceIndex)),
  onUpdateCharge: (charge: ICharge, serviceIndex: number, chargeIndex: number) =>
    dispatch(onUpdateCharge(charge, serviceIndex, chargeIndex)),
  onUpdateTariff: (serviceAndCharges: IServicesAndCharges[]) => dispatch(onUpdateTariff(serviceAndCharges)),
  getVoucherList: (companyCode: string) => dispatch(getVoucherList(companyCode)),
  getExchangeRate: (jobDetails: IJobDetails) => dispatch(getExchangeRate(jobDetails)),
  getUserDetail: (userName: string | null) => dispatch(getUserDetail(userName)),
  onSaveJobList: (serviceList: IServicesAndCharges[], jobDetails: IJobDetails, jobId: string, opId: number) =>
    dispatch(onSaveJobList(serviceList, jobDetails, jobId, opId)),
  onUpdateEditService: (serviceObj: IService, serviceIndex: number) => dispatch(onUpdateEditService(serviceObj, serviceIndex)),
  updateChargeValidation: (validationKey: string, chargeId: string, value: boolean) => dispatch(updateChargeValidation(validationKey, chargeId, value)),
});

const mapStateToProps = (state: any) => ({
  jobDetails: state.JobServices.jobDetails,
  services: state.JobServices.services,
  isError: state.JobServices.isError,
  isLoading: state.JobServices.isLoading,
  isJobSaving: state.JobServices.isJobSaving,
  isJobSaved: state.JobServices.isJobSaved,
  isViewer: state.JobServices.isViewer,
  message: state.JobServices.message,
  isRefreshLoader: state.JobServices.isRefreshLoader,
  userDetails: state.user.userDetail,
  serviceValidation: state.JobServices.serviceValidation,
  chargeValidation: state.JobServices.chargeValidation,
  isModified: state.JobServices.isModified,
  isServiceAdding: state.JobServices.isServiceAdding,
  isChargeAdding: state.JobServices.isChargeAdding,
  voucherList: state.JobServices.voucherList,
  exchangeRate: state.JobServices.exchangeRate,
  masterServiceValidation: state.JobServices.masterServiceValidation,
  masterChargeValidation: state.JobServices.masterChargeValidation,
  tariffServiceValidation: state.JobServices.tariffServiceValidation,
  tariffChargeValidation: state.JobServices.tariffChargeValidation
});

export default connect(mapStateToProps, mapDispatchToProps)(JobGrid);