import { Fragment, useCallback, useMemo } from 'react';
import {
  Grid
} from '../Jobs.styles';
import ServiceWrapper from './ServiceWrapper';
import Header from './Header';
import { Service } from 'Model/Job/JobService';
import { IServicesAndCharges } from 'Model/Job/types';

type Props = {
  services: any;
  expandAll?: boolean;
  onExpandAll: () => void;
  jobDetails: any;
  serviceList: any[];
  uomList: any[];
  voucherList: any[];
  currencyList: any[];
  billingParties: any[];
  exchangeRates: any[];
  taxList: any[];
  disableCost: boolean;
  disableServiceRequest: boolean;
  disableOperatingIncome: boolean;
  disableRevenue: boolean;
  disableBilling: boolean;
  setPartyValidModal: any;
  userDetails: any;
  onSelectAll: () => void;
  selectedList: Number[];
  isAllSelected: boolean;
  addCharges: (service: any) => void;
  onDeleteService: (servId: number) => void;
  onDeleteCharge: (servId: number, charId: number) => void;
  onSelectServices: (index: number) => void;
  onUpdateService: (service: any, serviceIndex: any) => void;
  onUpdateEditService: (serviceObj: any, serviceIndex: any) => void;
  onUpdateServiceDetails: (service: any, serviceIndex: any) => void;
  onUpdateCharge: (charge: any, serviceIndex: number, chargeIndex: number) => void;
  onShowTariff: (tariff: any) => void;
  updateChargeValidation: (validationKey: string, chargeId: string, value: boolean) => void;
  isValidParty: any;
  setValidParty: any;
  columnPreference: any;
  showOperatingIncome: any
  showServReq: any
  showBilling: any
  paymentTerms: any;
  chargeValidation?: any;
  serviceValidation?: any;
  masterChargeValidation?: any;
  masterServiceValidation?: any;
  isReadOnly: boolean;
};

const JobTable = (props: Props) => {
  const {
    services,
    jobDetails,
    disableCost,
    disableServiceRequest,
    disableOperatingIncome,
    disableRevenue,
    disableBilling,
    addCharges,
    onDeleteService,
    onDeleteCharge,
    serviceList,
    uomList,
    voucherList,
    currencyList,
    billingParties,
    taxList,
    onUpdateService,
    onUpdateCharge,
    expandAll,
    onExpandAll,
    onSelectAll,
    onUpdateServiceDetails,
    onSelectServices,
    selectedList,
    isAllSelected,
    exchangeRates,
    userDetails,
    isValidParty,
    setValidParty,
    setPartyValidModal,
    onShowTariff,
    columnPreference,
    showOperatingIncome,
    showServReq,
    showBilling,
    paymentTerms,
    onUpdateEditService,
    chargeValidation,
    serviceValidation,
    masterChargeValidation,
    masterServiceValidation,
    isReadOnly,
    updateChargeValidation
  } = props;

  const onAddCharge = useCallback((index: number) => {
    addCharges(index)
  }, []);

  const activeServices = useMemo(() => services.filter((service: IServicesAndCharges) => service.service.IsActive), [services]);

  return (
    <>
      <Grid id='jobTableView' data-testid="jobTableView">
        <Header
          isAllSelected={isAllSelected}
          columnPreference={columnPreference}
          jobDetails={jobDetails}
          disableCost={disableCost}
          disableServiceRequest={disableServiceRequest}
          disableOperatingIncome={disableOperatingIncome}
          disableRevenue={disableRevenue}
          disableBilling={disableBilling}
          showOperatingIncome={showOperatingIncome}
          showServReq={showServReq}
          showBilling={showBilling}
          onExpandAll={onExpandAll}
          onSelectAll={onSelectAll}
          isReadOnly={isReadOnly}
          showCostQuantity={jobDetails?.ShowCostQuantity}
        />
        {
          activeServices.map((item: any, index: number) =>
            <Fragment key={index}>
              <ServiceWrapper
                type="jobTable"
                id={item.ID}
                data-testid={'jobTableJobGrid'}
                key={index}
                index={index}
                userDetails={userDetails}
                columnPreference={columnPreference}
                service={item}
                expandAll={expandAll}
                jobDetails={jobDetails}
                services={services}
                exchangeRates={exchangeRates}
                serviceList={serviceList}
                uomList={uomList}
                voucherList={voucherList}
                billingParties={billingParties}
                currencyList={currencyList}
                taxList={taxList}
                paymentTerms={paymentTerms}
                disableCost={disableCost}
                disableServiceRequest={disableServiceRequest}
                disableOperatingIncome={disableOperatingIncome}
                disableRevenue={disableRevenue}
                disableBilling={disableBilling}
                onSelectServices={onSelectServices}
                onDeleteService={onDeleteService}
                onDeleteCharge={onDeleteCharge}
                onAddCharge={onAddCharge}
                onUpdateService={onUpdateService}
                onUpdateEditService={onUpdateEditService}
                onUpdateServiceDetails={onUpdateServiceDetails}
                onUpdateCharge={onUpdateCharge}
                selectedList={selectedList}
                onSelectAll={onSelectAll}
                onShowTariff={onShowTariff}
                isValidParty={isValidParty}
                setValidParty={setValidParty}
                setPartyValidModal={setPartyValidModal}
                chargeValidation={chargeValidation}
                serviceValidation={serviceValidation}
                masterChargeValidation={masterChargeValidation}
                masterServiceValidation={masterServiceValidation}
                isReadOnly={isReadOnly}
                updateChargeValidation={updateChargeValidation}
              /></Fragment>
          )
        }
      </Grid>
    </>
  )
}

export default JobTable;
