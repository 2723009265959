import React from 'react';
import { BsCheckCircle } from "react-icons/bs";
import JobTable from './components/JobTable';
import HeaderOptions from './components/HeaderOptions';
import FooterOptions from './components/FooterOptions';
import TemplateView from 'Components/Template';
import { JobServices, LoaderErrorMessage, LoaderOverview } from './Jobs.styles'
import Loader from 'Components/Loader';
import TariffModal from 'Components/TariffModal';
import PegModal from 'Controls/PegModal';
import axios from 'axios';
import EditForm from 'Containers/ServiceGrid/Components/EditForm';
import moment from "moment";
import { onBulkEdit, onBulkEditAll, applyTariff, getStatus } from 'Utils/JobGrid';
import { QuotationAlert } from 'Containers/Quotation/quotation.styles';
import PriceSummary from 'Containers/PriceSummary';
import Preferences from 'Components/ColumnPreference';
import { JOB_COLUMN } from 'Constants/ColumnFilterConstants';
import { getExchangeRates, shouldRenderBilling, shouldRenderOperatingIncome, shouldRenderServRequest, showToast, validateTemplate } from 'Utils/Generic';
import { jobgridProps, jobgridState } from './Modal'
import SummaryDetails from './components/SummaryDetails';
import _ from 'lodash';
import { getUserAccount } from 'Utils/AuthConfig';
import { BILLING_STATUS, BILLING_STATUS_OPTIONS_LIST, JobStatus, UnitPriceMinimum } from 'Constants/Constant';
import { toast } from 'react-toastify';
import PegButton from 'Controls/Button/PegButton';
import GridErrors from 'Components/GridErrors';
import { getGridErrorData } from 'Components/GridErrors/utils';
import { ICharge } from 'Model/Job/JobCharge';
import { ColumnPreferences } from 'Model/Common/ColumnPreferences';
import { IColumnGroupingPreferences, IJobDetails, IOperationalProcess, IServicesAndCharges } from 'Model/Job/types';
import { IValidationConfig } from 'Model/Common/types';
import QuotationSummary from "Components/QuotationSummary";
class JobGrid extends React.Component<jobgridProps, jobgridState> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedList: [],
      isAnyServiceWithSupplierInvoiceOrCRSelected: false,
      // selectedVoucherList: [],
      // selectedChargeList: [],
      selectedIndex: null,
      // currencies: [],
      operationalProcesses: [],
      // UOM: [],
      serviceList: [],
      uomList: [],
      voucherList: [],
      currencyList: [],
      billingParties: [],
      taxList: [],
      exchangeRates: [],
      paymentTerms: [],
      exchangeRatesLoading: true,
      jobDetail: null,
      expandAll: true,
      showDeleteModal: false,
      showEditModal: false,
      openOnlyBillingStatus: false,
      isAllSelected: false,
      showLeftArrow: false,
      showRightArrow: true,
      // grouping
      disableOperatingIncome: false,
      disableRevenue: false,
      disableCost: false,
      disableServiceRequest: false,
      disableBilling: false,
      showSavingModal: true,
      hasMissingFields: false,
      bulkEditList: {},
      bulkEditSR: {},
      showTemplateModal: false,
      showUndoModal: false,
      showPriceSummaryModal: false,
      isPriceSummaryPosting: false,
      showPartyValidModal: false,
      isSelected: false,
      isVoucherSelected: false,
      service: [],
      missingValidation: [],
      showTarrifModal: false,
      tariffData: null,
      applyingTarriff: false,
      isValidParty: true,
      showFilterModal: false,
      columnPreference: JOB_COLUMN,
      showConfirmBulkUpdate: false,
      gridErrors: [],
      exchangeError: false,
      deleteConfirmationMessage: "",
      noneOfTheServicesCanBeDeleted: false,
      showVesselMeasureModal: false,
    };
  }

  componentDidMount() {
    const {
      getUserDetail
    } = this.props
    const userName = getUserAccount('userName');
    getUserDetail(userName);
  }

  UNSAFE_componentWillReceiveProps = (nextProps: any) => {
    const {
      jobDetail,
    } = this.state
    const {
      match
    } = this.props
    const {
      userDetails,
      jobDetails
    } = nextProps

    let jobColumnGroupingPreferences = {} as IColumnGroupingPreferences;

    if (localStorage?.jobColumnGroupingPreferences) {
      jobColumnGroupingPreferences = JSON.parse(localStorage.jobColumnGroupingPreferences);
      this.setState({ ...jobColumnGroupingPreferences });
    }

    if (Object.keys(nextProps.jobDetails).length && !jobDetail) {
      this.setState({
        jobDetail: nextProps.jobDetails,
        disableOperatingIncome: jobColumnGroupingPreferences.disableOperatingIncome || nextProps.jobDetails.IsMaster ? true : false,
        disableRevenue: jobColumnGroupingPreferences.disableRevenue || nextProps.jobDetails.IsMaster ? true : false
      }, () => this.getDropdownValues(nextProps.jobDetails))
    }
    if ((userDetails?.Code)
      && !Object.keys(jobDetails).length) {
      this.getJobServices(match);
      if (localStorage?.jobColumnPreference) {

        const jobColumnsKeys = Object.keys(JOB_COLUMN);

        let columnObj = JSON.parse(localStorage.jobColumnPreference)
        if (columnObj[userDetails?.Username]) {
          const columnPreferencesKeys = Object.keys(columnObj[userDetails?.Username]);

          const jobsColumnsKeysString = jobColumnsKeys.sort().join();
          const columnPreferencesKeysString = columnPreferencesKeys.sort().join()

          if (jobsColumnsKeysString === columnPreferencesKeysString) {
            this.setState({ columnPreference: columnObj[userDetails?.Username] })
          } else {
            localStorage.removeItem("jobColumnPreference");
          }
        }
      }
    }

    if (this.props.jobDetails?.IsMaster) {
      this.setState({ disableRevenue: true, disableBilling: true })
    }
  }

  getJobServices = (match: any) => {
    const {
      params,
    } = match
    this.props.getJobGridServices(params)
  }

  handleScroll = (elmt: any) => {
    if (elmt.offsetWidth < elmt.offsetWidth + elmt.scrollLeft) {
      this.setState({ showLeftArrow: true })
    } else {
      this.setState({ showLeftArrow: false })
    }
    if (elmt.scrollWidth < elmt.offsetWidth + elmt.scrollLeft) {
      this.setState({ showRightArrow: false })
    } else {
      this.setState({ showRightArrow: true })
    }
  }

  getDropdownValues = (jobDetail: any) => {
    this.getServices(jobDetail);
    this.getCurrencies();
    this.getUOM();
    this.getTaxes();
    this.getBillingParties(jobDetail);
    this.getExchangeRates(jobDetail);
    this.props.getVoucherList(jobDetail?.CompanyCode);
    this.getPaymentTerms()
  }

  getPaymentTerms = () => {
    axios.get(`/getPaymentTerms`).then((response) => {
      this.setState({ paymentTerms: response.data });
    })
  }

  getServices = (jobDetail: IJobDetails) => {
    const {
      match
    } = this.props
    axios.get(`/jobService-GetAllBpServices?jobId=${jobDetail.LocalJobId}&bpCode=${jobDetail.ProductCode}&companyCode=${jobDetail.CompanyCode}&opId=${match?.params.opId ? match?.params.opId : ''}`
    ).then((response) => {
      this.setState({ serviceList: response.data });
    })
  }

  getUOM = () => {
    axios.get(`/mdm-unit-of-measure?uomShortNames=&uomCodes=&searchText=`).then((response) => {
      this.setState({ uomList: response.data })
    })
  }

  getCurrencies = () => {
    axios.get(`/mdm-currency?searchText=&code=`).then((response) => {
      this.setState({ currencyList: response.data })
    })
  }

  getBillingParties = (jobDetail: any) => {
    const {
      match
    } = this.props
    if (match?.params.opId) {
      axios.get(`/jobService-getAllPartiesByOpId?opId=${match?.params.opId}`).then((responseChild) => {
        this.setState({ billingParties: responseChild.data.filter((item: any) => item.isBillingParty) })
      })
    } else {
      if (jobDetail.LocalJobId) {
        axios.get(`/jobService-getAllOpProcessByJobId?jobId=${jobDetail.LocalJobId}`).then((response) => {
          if (!response.data.errorCode) {
            this.setState({ operationalProcesses: response.data })

            const optionIds = response.data.map((item: any) => item.JobOperationalProcessId)
            if (optionIds || optionIds.length) {
              axios.get(`/jobService-getAllPartiesByOpId?opId=${optionIds}`).then((responseChild) => {
                this.setState({ billingParties: responseChild.data.filter((item: any) => item.isBillingParty) })
              })
            }
          }
        })
      }
    }
  }

  getTaxes = () => {
    const { jobDetail } = this.state;
    jobDetail && axios.get(`/oum-get-tax?company-code=${jobDetail.CompanyCode}`).then((response) => {
      this.setState({ taxList: response.data })
    })
  }

  getExchangeRates = async (jobDetail: any) => {
    const { exchangeRate } = this.props;
    // this.props.getExchangeRate(jobDetail)

    const thisGrid = this;
    const exchangeErrorTimeout = setTimeout(function () {
      thisGrid.setState({ exchangeError: true });
    }, 5000);

    axios.get(`/jobService-getAllCurrencyExchangeRates?company-code=${jobDetail.CompanyCode}`).then((response: any) => {
      clearTimeout(exchangeErrorTimeout);
      if (response.data) {
        this.setState({ exchangeRates: response.data, exchangeRatesLoading: false })
      } else {
        this.setState({ exchangeError: true });
      }
    }).catch(() => {
      this.setState({ exchangeError: true });
    })

  }

  onAddService = async () => {
    const element = document.getElementById('jobTableView');
    await this.props.setAddServiceLoading(true);
    await this.props.addServices();
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }

  onEditOption = () => {
    const { services } = this.props;
    const { selectedList } = this.state;

    let openOnlyBillingStatus = false;

    for (let i = 0; i < selectedList.length; i++) {
      const service = services[selectedList[i]];

      let emptyBillingStatusForCharges = true;

      service.charges?.forEach((charge: any) => {
        if (charge.BillingStatus !== "") {
          emptyBillingStatusForCharges = false;
        }
      });

      if (!openOnlyBillingStatus) {
        openOnlyBillingStatus = openOnlyBillingStatus || !emptyBillingStatusForCharges;
      } else {
        break;
      }
    }

    this.setState({ showEditModal: true, openOnlyBillingStatus: openOnlyBillingStatus })
  }

  onEditOptionCancel = () => {
    this.setState({ showEditModal: false })
  }

  editOptionConfirmPreCheck = () => {
    let {
      selectedList,
      bulkEditList } = this.state;
    let {
      services
    } = this.props;

    let selectedServices = services.filter((x: any, index: any) => selectedList.indexOf(index) != -1);
    const billableServices = services.filter((srvObj: any) => (
      srvObj.charges?.some((charge: any) => (
        charge.BillingStatus === BILLING_STATUS.ASSIGNED ||
        charge.BillingStatus === BILLING_STATUS.BILLING_IN_PROGRESS))) || srvObj.service.BillingStatus === BILLING_STATUS.SEND_FOR_BILLING);

    const hasNoBillable = billableServices.findIndex((srvObj: any) => !!srvObj?.service?.IsBillable)

    if (selectedServices.every((x: any) => !x.service.IsBillable) && bulkEditList.BillingStatus != "" && billableServices?.length > 0 && hasNoBillable == -1) {
      this.setState({ showConfirmBulkUpdate: true })
    }
    else {
      this.onEditOptionConfirm();
    }
  }

  onEditOptionConfirm = () => {
    let {
      selectedList,
      bulkEditSR,
      bulkEditList,
      isAllSelected,
      exchangeRates
    } = this.state
    let {
      services,
      onBulkUpdate,
      jobDetails
    } = this.props;

    let updatedList =
      isAllSelected ?
        onBulkEditAll(services, selectedList, bulkEditList, bulkEditSR, jobDetails, exchangeRates)
        :
        onBulkEdit(services, selectedList, bulkEditList, bulkEditSR, jobDetails, exchangeRates);
    onBulkUpdate(updatedList)
    this.setState({
      showEditModal: false,
      selectedList: [],
      isAllSelected: false,
      bulkEditList: {},
      bulkEditSR: {},
      isAnyServiceWithSupplierInvoiceOrCRSelected: false
    });
  }

  onBulkUpdateDenied = () => {
    this.setState({
      showConfirmBulkUpdate: false
    })
  }
  onBulkUpdateConfirmed = () => {
    this.setState({
      showConfirmBulkUpdate: false
    })
    this.onEditOptionConfirm();
  }

  setPartyValidModal = (value: boolean) => {
    this.setState({ showPartyValidModal: value })
  }

  onEditServices = (event: any, key: any) => {
    let {
      bulkEditList,
      bulkEditSR,
      billingParties
    } = this.state
    const {
      jobDetails,
      services
    } = this.props
    if (key === 'startDate') {
      bulkEditSR.StartDateTime = moment(event.value).format()
    }
    if (key === 'editDate') {
      bulkEditSR.EndDateTime = moment(event.value).format()
    }
    if (key === 'serviceStatus') {
      if (event) {
        bulkEditSR.ServiceStatus = event.label
      } else {
        bulkEditSR.ServiceStatus = ""
      }
    }
    if (key === 'billingStatus') {
      const { selectedList, isAllSelected } = this.state;
      if (event) {
        if (event.name === "Send for Billing") {
          this.setPartyValidModal(true)
          getStatus(jobDetails, services, null, billingParties, 'bulkEdit', selectedList, isAllSelected).then((response: any) => {
            this.setValidParty(response?.IsValidParty);
            if (response?.IsValidParty) {
              this.setPartyValidModal(false)
              showToast('Party is valid, you can proceed for "Send to Billing"', 'success', 7000, 'top-center', 'colored')
              bulkEditList.BillingStatus = event.name;
            } else {
              this.setPartyValidModal(false)
              bulkEditList.BillingStatus = "";
              response?.Message.map((el: any) => {
                showToast(`${el}, cannot be "Send to Billing" !`, 'error', 7000, 'top-center', 'colored')
              })
            }
          })
        }
        bulkEditList.BillingStatus = event.name;
      } else {
        bulkEditList.BillingStatus = ""
      }
    }
    if (key === 'billingCurrency') {
      if (event) {
        bulkEditList.BillingCurrencyCode = event.code
      } else {
        bulkEditList.BillingCurrencyCode = ""
      }
    }
    if (key === 'priceCurrency') {
      if (event) {
        let priceExRate = this.getConvertionRate(event.code, jobDetails?.CurrencyCode)
        bulkEditList.PriceCurrencyCode = event.code
        bulkEditList.PriceExchangeRate = priceExRate ? priceExRate.ExchangeRate : 0
      } else {
        bulkEditList.PriceCurrencyCode = "";
        bulkEditList.PriceExchangeRate = 0
      }
    }
    if (key === 'costCurrency') {
      if (event) {
        let costExRate = this.getConvertionRate(event.code, jobDetails?.CurrencyCode)
        bulkEditList.CostCurrencyCode = event.code
        bulkEditList.CostExchangeRate = costExRate ? costExRate.ExchangeRate : 0
      } else {
        bulkEditList.CostCurrencyCode = "";
        bulkEditList.CostExchangeRate = 0;
      }
    }
    if (key === 'supplier') {
      if (event) {
        bulkEditList.SupplierName = event.name
        bulkEditList.SupplierCode = event.code
      } else {
        bulkEditList.SupplierName = ""
        bulkEditList.SupplierCode = ""
      }
    }
    if (key === 'IsBillable') {
      bulkEditList.IsBillable = event?.value;
      if (!bulkEditList.IsBillable) {
        bulkEditList.BillingStatus = ''
      }
    }
    this.setState({ bulkEditSR, bulkEditList })
  }

  getConvertionRate = (fromCurrency: any, targetCurrency: any) => {
    const {
      exchangeRates
    } = this.state
    let exchangeRate: any = getExchangeRates(exchangeRates, fromCurrency, targetCurrency)
    return exchangeRate;
  }

  onExpandAll = () => {
    this.setState({ expandAll: !this.state.expandAll })
  }

  onDeleteOption = () => {
    const { services } = this.props;

    let selectedList = this.state.selectedList;
    let cannotDeleteList: string[] = [];

    let deleteConfirmationMessage;
    let noneOfTheServicesCanBeDeleted = false;

    selectedList.map((serviceIndex: number) => {
      const serviceAndCharges = services[serviceIndex];

      const isSupplierInvoiceAvailable = serviceAndCharges.supplierDetails?.supplierInvoice?.[0];
      const isSupplierCreditNoteAvailable = serviceAndCharges.supplierDetails?.creditNote?.[0];

      const canDeleteService = !(BILLING_STATUS_OPTIONS_LIST.includes(serviceAndCharges.service?.BillingStatus!) || isSupplierInvoiceAvailable || isSupplierCreditNoteAvailable || serviceAndCharges.service?.VoucherStatus === "Approved");

      if (!canDeleteService) {
        cannotDeleteList.push(serviceAndCharges?.service?.Name!);
      }
    })

    if (cannotDeleteList.length > 0) {
      deleteConfirmationMessage = <div>
        These services cannot be deleted as they have approved vouchers ({cannotDeleteList.join(', ')}).
        {cannotDeleteList.length < selectedList.length ? <>
          <br /><br />
          Delete other selected services?
        </> : null}
      </div>;

      if (cannotDeleteList.length < selectedList.length) {
        noneOfTheServicesCanBeDeleted = false;
      } else {
        noneOfTheServicesCanBeDeleted = true
      }

    } else {
      deleteConfirmationMessage = "Are you sure to delete the selected items";
      noneOfTheServicesCanBeDeleted = false;
    }

    this.setState({ showDeleteModal: true, deleteConfirmationMessage, noneOfTheServicesCanBeDeleted })
  }

  onDeleteModalCancel = () => {
    this.setState({ showDeleteModal: false })
  }

  onDeleteModalConfirm = async () => {
    const {
      services,
      onMultiDeleteServices
    } = this.props

    const {
      selectedList
    } = this.state

    let deleteList: number[] = [];

    selectedList.map((serviceIndex: number) => {
      const serviceAndCharges = services[serviceIndex];

      const isSupplierInvoiceAvailable = serviceAndCharges.supplierDetails?.supplierInvoice?.[0];
      const isSupplierCreditNoteAvailable = serviceAndCharges.supplierDetails?.creditNote?.[0];

      const canDeleteService = !(BILLING_STATUS_OPTIONS_LIST.includes(serviceAndCharges.service?.BillingStatus!) || isSupplierInvoiceAvailable || isSupplierCreditNoteAvailable || serviceAndCharges.service?.VoucherStatus === "Approved");

      if (canDeleteService) {
        deleteList.push(serviceIndex);
      }
    })

    await onMultiDeleteServices(deleteList)
    this.setState({ showDeleteModal: false, selectedList: [], isAllSelected: false })
  }

  onSelectServices = (index: number) => {
    this.setState({ selectedIndex: index })
    let selectedList = this.state.selectedList
    if (!selectedList.includes(index)) {
      selectedList.push(index);
    } else {
      selectedList.splice(selectedList.indexOf(index), 1);
    }
    this.setState({
      selectedList,
      isAllSelected: false,
      isAnyServiceWithSupplierInvoiceOrCRSelected: this.getIsAnyServiceWithSupplierInvoiceOrCR(selectedList)
    });
  }

  onClickOptions = (type: string) => {
    const { disableRevenue, disableCost, disableServiceRequest, disableOperatingIncome, disableBilling } = this.state;

    const jobColumnGroupingPreferences: IColumnGroupingPreferences = {
      disableRevenue,
      disableCost,
      disableServiceRequest,
      disableOperatingIncome,
      disableBilling
    }

    if (type === 'actuals') {
      jobColumnGroupingPreferences.disableRevenue = !this.state.disableRevenue;
      this.setState({ disableRevenue: jobColumnGroupingPreferences.disableRevenue })
    }
    if (type === 'estimates') {
      jobColumnGroupingPreferences.disableCost = !this.state.disableCost;
      this.setState({ disableCost: jobColumnGroupingPreferences.disableCost })
    }
    if (type === 'SrRequest') {
      jobColumnGroupingPreferences.disableServiceRequest = !this.state.disableServiceRequest;
      this.setState({ disableServiceRequest: jobColumnGroupingPreferences.disableServiceRequest })
    }
    if (type === 'OpIncome') {
      jobColumnGroupingPreferences.disableOperatingIncome = !this.state.disableOperatingIncome;
      this.setState({ disableOperatingIncome: jobColumnGroupingPreferences.disableOperatingIncome })
    }
    if (type === 'billing') {
      jobColumnGroupingPreferences.disableBilling = !this.state.disableBilling;
      this.setState({ disableBilling: jobColumnGroupingPreferences.disableBilling })
    }

    localStorage.setItem('jobColumnGroupingPreferences', JSON.stringify(jobColumnGroupingPreferences));
  }

  onSave = async () => {
    const {
      onSaveJobList,
      match,
      services,
      jobDetails,
    } = this.props
    if (services.length > 0) {
      const isValidated = await this.checkValidation(services, 'saveServices')
      if (isValidated) {
        onSaveJobList(services, jobDetails, match.params.id, match.params.opId)
        this.setState({ hasMissingFields: false })
      } else {
        this.setState({ hasMissingFields: true })
      }
    }
  }

  onClickImportTemplate = () => {
    this.setState({ showTemplateModal: true })
  }

  onCloseTemplateModal = () => {
    this.setState({ showTemplateModal: false })
  }


  onSubmitImportTemplate = async (template: any) => {
    const { onAddServiceTemplate, jobDetails } = this.props;
    const { operationalProcesses } = this.state;

    const operationProcessIds = operationalProcesses.map((op: IOperationalProcess) => op.JobOperationalProcessId);

    const operationalProcessId = this.props.match?.params.opId;

    let templatesToImport;

    if (operationalProcessId) {
      templatesToImport = template.filter((service: any) => {
        return service?.services?.OperationalProcessFk == operationalProcessId;
      });
    } else {
      templatesToImport = template.filter((service: any) => {
        return operationProcessIds.includes(service?.services?.OperationalProcessFk);
      });
    }

    const { CompanyCode, CustomerCode } = jobDetails
    const { errors, services } = await validateTemplate(templatesToImport, CompanyCode, CustomerCode, jobDetails.IsMaster)

    errors.forEach((error: any) => {
      toast.error(error.message, {
        position: "top-center",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        style: { width: '400px' },
      })
    })

    onAddServiceTemplate(services)

    setTimeout(() => {
      this.setState({ showTemplateModal: false });
    }, 1500);
  };



  onUndoChange = () => {
    this.setState({ showUndoModal: true })
  }

  onUndoModalCancel = () => {
    this.setState({ showUndoModal: false })
  }

  onUndoModalConfirm = () => {
    const { match, undoChanges } = this.props
    undoChanges(match.params)
    this.setState({ showUndoModal: false })
  }

  onPriceSummaryConfirm = () => {
    // her we will add the api once we get the api
    this.setState({ showPriceSummaryModal: false })
  }

  onClickPriceSummary = () => {
    this.setState({ showPriceSummaryModal: true })
  }

  onClosePriceSummary = () => {
    this.setState({ showPriceSummaryModal: false })
  }

  sendPricingSummary = (pricingSummary: any) => {
    this.postPricingSummary(pricingSummary)
  }

  postPricingSummary = (pricingSummary: any) => {
    const { jobDetails } = this.props
    this.setState({ isPriceSummaryPosting: true })
    axios.post(`/generatePriceSummary`, {
      "LocalJobId": jobDetails.LocalJobId,
      "PriceSummaryData": [...pricingSummary.priceSummaryData]
    }).then(() => {
      this.setState({ showPriceSummaryModal: false, isPriceSummaryPosting: false })
    })
  }

  onSelectAll = () => {
    this.setState({ isAllSelected: !this.state.isAllSelected },
      () => this.setSelectedArray(this.state.isAllSelected))
  }

  setSelectedArray = (isAllSelected: boolean) => {
    const {
      services: allServices
    } = this.props

    const services = allServices.filter((service: IServicesAndCharges) => service.service.IsActive)

    if (isAllSelected) {

      let tempSelectedList: number[] = []

      services.forEach((item, index) => {

        const isChargeExist = item
          .charges
          .some((charge: ICharge) => !charge.BillingStatus || !BILLING_STATUS_OPTIONS_LIST.includes(charge.BillingStatus))

        if (isChargeExist) {
          tempSelectedList.push(index)
        }
      })

      this.setState({
        selectedList: [...tempSelectedList],
        isAnyServiceWithSupplierInvoiceOrCRSelected: this.getIsAnyServiceWithSupplierInvoiceOrCR([...tempSelectedList])
      })
    } else {
      this.setState({
        selectedList: [],
        isAnyServiceWithSupplierInvoiceOrCRSelected: false
      })
    }
  }

  onApplyTariff = async () => {
    const {
      services
    } = this.props
    const {
      selectedList,
      isAllSelected,
    } = this.state
    let list = services;
    if (!isAllSelected) {
      list = selectedList.map((item: number) => services[item])
    } else {
      list = services.filter((item: any) => item.service.IsActive && !BILLING_STATUS_OPTIONS_LIST.includes(item.service.BillingStatus))
    }
    let isValidated = await this.checkValidation(list, 'tariff')
    if (isValidated) {
      this.setState({ applyingTarriff: true })
      axios.post(`/jobService-getTariffDetails`, {
        ServicesAndCharges: list
      }).then((response) => {
        let updatedServices = applyTariff(selectedList, services, response.data, isAllSelected)
        this.props.onUpdateTariff(updatedServices)
        this.setState({ applyingTarriff: false, selectedList: [], isAllSelected: false, isAnyServiceWithSupplierInvoiceOrCRSelected: false })
      }).catch((err) => {
        this.setState({ applyingTarriff: false, selectedList: [], isAllSelected: false, isAnyServiceWithSupplierInvoiceOrCRSelected: false })
      })
    }
  }

  checkValidation = async (services: any, type: string) => {

    let {
      chargeValidation,
      serviceValidation,
      masterChargeValidation,
      masterServiceValidation,
      tariffServiceValidation,
      tariffChargeValidation,
      jobDetails,
    } = this.props

    const {
      billingParties
    } = this.state;

    let isValidated = true;
    let serValidation = jobDetails.IsMaster ? masterServiceValidation : serviceValidation;
    let charValidation = jobDetails.IsMaster ? masterChargeValidation : chargeValidation;
    if (type === 'tariff') {
      serValidation = tariffServiceValidation
      charValidation = tariffChargeValidation
    }

    let errors: any[] = [];

    let missingValidation: any = []

    await services.forEach((obj: any) => {

      if (obj.service.IsActive) {
        Object.keys(serValidation).forEach(function (key) {
          if (!obj.service[key]) {
            isValidated = false;
            missingValidation.push(key);
            errors.push(getGridErrorData({ service: obj?.service, key, type: "required" }));
          }
        })

        if (obj.service.IsCashVoucherNeeded && !obj.service.VoucherType) {
          isValidated = false;
          missingValidation.push("Voucher Type");
          errors.push(getGridErrorData({ service: obj?.service, key: "VoucherType", type: "required" }));
        }

        obj.charges.forEach((charge: any) => {
          if (charge.IsActive) {
            Object.keys(charValidation).forEach((key) => {
              if (key === 'UnitPrice') {
                let unitPrice = charge[key];
                if (!unitPrice && unitPrice !== 0) {
                  isValidated = false;
                  missingValidation.push(key);
                  errors.push(getGridErrorData({ service: obj?.service, charge, key, type: "required" }));
                }
                else if (!(unitPrice >= UnitPriceMinimum) && obj.service.IsBillable) {
                  isValidated = false;
                  missingValidation.push('UnitPrice0');
                  errors.push(getGridErrorData({ service: obj?.service, charge, key, type: "required" }));
                }
              }
              else if (key === 'CustomerService') {
                const customerServiceValidation = charValidation[key] as IValidationConfig;
                const shouldValidateCharge = customerServiceValidation[charge.Code as string];

                if (charge.Id === 0 && shouldValidateCharge && !charge?.GSServiceCode) {
                  isValidated = false;
                  missingValidation.push(key);
                  errors.push(getGridErrorData({ service: obj?.service, charge, key, type: "required" }));
                }
              }
              else if (key === 'dolphinCode') {

                const customerServiceValidation = charValidation[key] as IValidationConfig;
                const shouldValidateCharge = customerServiceValidation[charge.Code as string];

                if (charge.Id === 0 && shouldValidateCharge) {
                  isValidated = false;
                  missingValidation.push(key);
                  errors.push(getGridErrorData({
                    service: obj?.service,
                    charge,
                    key: "DolphinCode",
                    type: "dolphinCode",
                    message: " - (Note that the charge selected has missing dolphin codes. Please contact finance/accounts to check and resolve)"
                  }
                  ));
                }
              }
              else if (key === 'OperationalProcessPartyFk') {
                const isValid = billingParties.some(party => party.operationalProcessPartyId === charge.OperationalProcessPartyFk)

                if (!isValid) {
                  isValidated = false;
                  missingValidation.push(key);
                  errors.push(getGridErrorData({ service: obj?.service, charge, key, type: "required" }));
                }
              }
              else if (!charge[key]) {
                if (key === 'UnitCost') {
                  if (isNaN(charge[key]) || charge[key] === null || charge[key] === undefined || charge[key] === '') {
                    isValidated = false;
                    missingValidation.push(key);
                    errors.push(getGridErrorData({ service: obj?.service, charge, key, type: "required" }));

                  }
                }
                else {
                  isValidated = false;
                  missingValidation.push(key);
                  errors.push(getGridErrorData({ service: obj?.service, charge, key, type: "required" }));

                }
              }
            })
          }
        })


      }
    })

    const billableServices = services.filter((srvObj: any) => (
      srvObj.charges?.some((charge: any) => (
        charge.BillingStatus === BILLING_STATUS.ASSIGNED ||
        charge.BillingStatus === BILLING_STATUS.BILLING_IN_PROGRESS))) || srvObj.service.BillingStatus === BILLING_STATUS.SEND_FOR_BILLING);

    const hasNoBillable = billableServices.findIndex((srvObj: any) => !!srvObj?.service?.IsBillable || srvObj.charges.some((charge: any) => charge.BillingStatus === BILLING_STATUS.BILLING_IN_PROGRESS))

    if (billableServices?.length > 0 && hasNoBillable == -1) {
      isValidated = false;
      errors.push(getGridErrorData({
        service: null,
        charge: null,
        key: "Missing Job Revenue",
        type: "missingJobRevenue",
        message: "Please note that the selected service lines do not have Job Revenue and cannot be sent to billing. Kindly add other services with Job Revenue to proceed."
      }
      ));
    }

    if (type !== 'tariff') {
      this.setState({ missingValidation: _.uniq(missingValidation) })
    }
    this.setState({ gridErrors: errors });

    return isValidated;
  }

  setValidParty = (res: boolean) => {
    this.setState({ isValidParty: res })
  }
  onCloseTariffModal = () => {
    this.setState({ showTarrifModal: false })
  }

  onShowTariffModal = (value: ICharge) => {
    this.setState({ showTarrifModal: true, tariffData: value })
  }

  onClickFilterColumn = () => {
    this.setState({ showFilterModal: !this.state.showFilterModal })
  }

  onSubmitFilterModal = () => {
    this.setState({ showFilterModal: false })
  }

  onSavePreferences = (list: ColumnPreferences) => {
    const {
      userDetails
    } = this.props
    let columnObj: any = {
      [userDetails?.Username]: list
    }
    localStorage.setItem('jobColumnPreference', JSON.stringify(columnObj))
    this.setState({ showFilterModal: false, columnPreference: list })
    toast.success('Column Preferences Updated', {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
      style: { width: '400px' },
    })
  }

  getIsAnyServiceWithSupplierInvoiceOrCR = (selectedIndexes: number[]) => {
    return !!selectedIndexes
      .map((i: number) => this.props.services[i])
      .find((s: any) => !!(s?.supplierDetails?.supplierInvoice?.[0] || s?.supplierDetails?.creditNote?.[0]))
  }


  render() {
    const {
      isLoading,
      isError,
      jobDetails,
      isRefreshLoader,
      message,
      services,
      addCharges,
      deleteServices,
      deleteCharges,
      onUpdateService,
      onUpdateEditService,
      onUpdateCharge,
      isJobSaving,
      isJobSaved,
      onUpdateServiceDetails,
      isModified,
      isServiceAdding,
      voucherList,
      userDetails,
      chargeValidation,
      serviceValidation,
      masterChargeValidation,
      masterServiceValidation,
      updateChargeValidation,
      isViewer
    } = this.props

    const {
      disableCost,
      disableServiceRequest,
      disableOperatingIncome,
      disableRevenue,
      disableBilling,
      selectedList,
      showDeleteModal,
      serviceList,
      uomList,
      currencyList,
      billingParties,
      taxList,
      showEditModal,
      openOnlyBillingStatus,
      expandAll,
      hasMissingFields,
      showTemplateModal,
      showUndoModal,
      showPriceSummaryModal,
      showPartyValidModal,
      jobDetail,
      isPriceSummaryPosting,
      operationalProcesses,
      isAllSelected,
      exchangeRatesLoading,
      exchangeRates,
      bulkEditSR,
      bulkEditList,
      missingValidation,
      showTarrifModal,
      tariffData,
      applyingTarriff,
      isValidParty,
      showFilterModal,
      columnPreference,
      paymentTerms,
      showConfirmBulkUpdate,
      gridErrors,
      // showRefreshModal,
      exchangeError,
      deleteConfirmationMessage,
      noneOfTheServicesCanBeDeleted,
      showVesselMeasureModal
    } = this.state

    const showOperatingIncome = shouldRenderOperatingIncome(columnPreference);
    const showServReq = shouldRenderServRequest(columnPreference);
    const showBilling = shouldRenderBilling(columnPreference);
    const isGridReadonly = this.props?.jobDetails?.JobStatusFk === JobStatus.FinanciallyClosed || isViewer

    if (isRefreshLoader) {
      return (
        <div id="refreshModal">
          <PegModal
            isOpen={isRefreshLoader}
            alertModal={true}
            showTemplate={true}
            isCenter={true}>
            <div style={{ overflow: 'hidden', padding: 30 }}>
              <QuotationAlert>{message}</QuotationAlert>
              <Loader gridLoader={true} color={'#001F59'} />
              <PegButton
                id="refreshModalButton"
                type="button"
                variant="Primary"
                data-testid="refreshModalButton"
                styleObject={{ position: 'relative', top: '15px', display: 'flex', margin: 'auto' }}
                onClick={() => window.location.reload()}
              >
                Refresh Page
              </PegButton>
            </div>
          </PegModal>
        </div>
      )
    }

    if (isLoading || exchangeRatesLoading) {
      return (
        <LoaderOverview data-testid="jobgridLoader" role="jobgridLoader">
          {exchangeError ? <LoaderErrorMessage>Could not load currency exchange data</LoaderErrorMessage> : null}
          <Loader
            size={50}
            color={'#adccf4'}
          />
        </LoaderOverview>
      );
    }

    if (isError) {
      return (
        <div data-testid="jobGridError">
          something went wrong
        </div>
      );
    }

    const showVesselDetails = jobDetails?.VesselImoNumber && jobDetails?.VesselImoNumber?.toString().trim().length > 0 && jobDetails?.VesselImoNumber !== "N/A"

    return (
      <JobServices data-testid="jobGridServices">
        {gridErrors.length ? <GridErrors errors={gridErrors} /> : null}

        <SummaryDetails
          data-testid="jobGridSummaryDetails"
          services={services}
          jobDetails={jobDetails}
          userDetails={userDetails}
        />
        <HeaderOptions
          data-testid="jobGridHeader"
          services={services}
          selectedList={selectedList}
          jobDetails={jobDetails}
          disableCost={disableCost}
          disableServiceRequest={disableServiceRequest}
          disableOperatingIncome={disableOperatingIncome}
          disableRevenue={disableRevenue}
          disableBilling={disableBilling}
          isModified={isModified}
          showOperatingIncome={showOperatingIncome}
          showServReq={showServReq}
          showBilling={showBilling}
          onClickImportTemplate={this.onClickImportTemplate}
          onClickPriceSummary={this.onClickPriceSummary}
          onClickOptions={(type: any) => this.onClickOptions(type)}
          onClickFilterColumn={this.onClickFilterColumn}
          templateImportEnabled={!isGridReadonly}
        />
        {showVesselDetails && <div className='absolute right-0 top-96 transform -rotate-90 w-8 shadow-lg' style={{ zIndex: 100 }}>
          <PegButton
            id="vesssel_measure"
            type="button"
            variant="Primary"
            styleObject={{ minWidth: '10rem' }}
            onClick={() => this.setState({ showVesselMeasureModal: true })}
          >
            Vessel Measure
          </PegButton>
        </div>}
        <div id="vesselMeasureModal">
          <QuotationSummary onClose={() => this.setState({ showVesselMeasureModal: false })} showVesselMeasureModal={showVesselMeasureModal} />
        </div>
        <JobTable
          disableCost={disableCost}
          disableServiceRequest={disableServiceRequest}
          disableOperatingIncome={disableOperatingIncome}
          disableRevenue={disableRevenue}
          disableBilling={disableBilling}
          showOperatingIncome={showOperatingIncome}
          showServReq={showServReq}
          showBilling={showBilling}
          expandAll={expandAll}
          services={services}
          columnPreference={columnPreference}
          jobDetails={jobDetails}
          serviceList={serviceList}
          uomList={uomList}
          voucherList={voucherList}
          selectedList={selectedList}
          isAllSelected={isAllSelected}
          onExpandAll={this.onExpandAll}
          currencyList={currencyList}
          billingParties={billingParties}
          taxList={taxList}
          exchangeRates={exchangeRates}
          paymentTerms={paymentTerms}
          onDeleteService={deleteServices}
          onSelectServices={(index: number) => this.onSelectServices(index)}
          onDeleteCharge={deleteCharges}
          addCharges={addCharges}
          onUpdateCharge={onUpdateCharge}
          onUpdateService={onUpdateService}
          onUpdateEditService={onUpdateEditService}
          onUpdateServiceDetails={onUpdateServiceDetails}
          onSelectAll={() => this.onSelectAll()}
          userDetails={userDetails}
          onShowTariff={(tariff: any) => this.onShowTariffModal(tariff)}
          isValidParty={isValidParty}
          setValidParty={this.setValidParty}
          setPartyValidModal={this.setPartyValidModal}
          chargeValidation={chargeValidation}
          serviceValidation={serviceValidation}
          masterChargeValidation={masterChargeValidation}
          masterServiceValidation={masterServiceValidation}
          updateChargeValidation={updateChargeValidation}
          isReadOnly={isGridReadonly}
        />
        {!isGridReadonly ? <FooterOptions
          services={services}
          missingValidation={missingValidation}
          hasMissingFields={hasMissingFields}
          selectedList={selectedList}
          onAddService={this.onAddService}
          onDeleteOption={this.onDeleteOption}
          onEditOption={this.onEditOption}
          onSave={this.onSave}
          isModified={isModified}
          onUndoChange={this.onUndoChange}
          onApplyTariff={this.onApplyTariff}
          isServiceAdding={isServiceAdding}
          isAnyServiceWithSupplierInvoiceOrCR={this.state.isAnyServiceWithSupplierInvoiceOrCRSelected}
        /> : null}
        <PegModal
          isOpen={showDeleteModal}
          closeModal={this.onDeleteModalCancel}
          tertiaryAction={this.onDeleteModalConfirm}
          modalTitle={"Delete Items"}
          buttonText={"Delete"}
          modalContent={deleteConfirmationMessage}
          disableConfirmButton={noneOfTheServicesCanBeDeleted}
        />
        <PegModal
          isOpen={isJobSaving}
          alertModal={true}
          showTemplate={true}
          isCenter={true}>
          <div style={{ overflow: 'hidden', padding: 12 }}>
            <QuotationAlert data-testid="saveJobGridModal">{isJobSaved ? "Records have been saved successfully" : "Please wait while saving"}</QuotationAlert>
            {isJobSaved ?
              <BsCheckCircle style={{
                height: "50px",
                width: "100%",
                color: "#2cd334"
              }} /> :
              <Loader
                size={50}
                color={'#adccf4'}
              />}
          </div>
        </PegModal>
        <PegModal
          isOpen={applyingTarriff}
          alertModal={true}
          showTemplate={true}
          isCenter={true}>
          <div style={{ overflow: 'hidden', padding: 12 }}>
            <QuotationAlert>Please wait while fetching tariff</QuotationAlert>
            <Loader
              size={50}
              color={'#adccf4'}
            />
          </div>
        </PegModal>
        <div id="jobGridEdit">
          <PegModal
            isOpen={showEditModal}
            closeModal={this.onEditOptionCancel}
            tertiaryAction={this.editOptionConfirmPreCheck}
            modalTitle={"Edit Items"}
            buttonText={"Update"}
            isCenter={true}
            showTemplate={true}
          >
            <EditForm
              isMaster={jobDetails?.IsMaster}
              currencyList={currencyList}
              bulkEditSR={bulkEditSR}
              bulkEditList={bulkEditList}
              jobDetails={jobDetails}
              isValidParty={isValidParty}
              onEditServices={(event: any, key: any) => this.onEditServices(event, key)}
              isCostEditDisabled={this.state.isAnyServiceWithSupplierInvoiceOrCRSelected}
              openOnlyBillingStatus={openOnlyBillingStatus}
            />

          </PegModal>
          <PegModal
            isOpen={showConfirmBulkUpdate}
            closeModal={this.onBulkUpdateDenied}
            isCenter={true}
            tertiaryAction={this.onBulkUpdateConfirmed}
            modalTitle={"One or more services are marked as non billable so cannot be sent to billing."}
            buttonText={"Proceed"}
            modalContent={"Do you wish to proceed?"}
          />
        </div>
        <div id="importTemplateModal">
          <PegModal
            isOpen={showTemplateModal}
            alertModal={true}
            isCenter={true}
            showTemplate={true}>
            <TemplateView
              details={jobDetail}
              type={'job'}
              onSubmitImportTemplate={this.onSubmitImportTemplate}
              onCloseTemplateModal={this.onCloseTemplateModal}
            />
          </PegModal>
        </div>
        <div id="tariffModal">
          <PegModal
            isOpen={showTarrifModal}
            closeModal={this.onCloseTariffModal}
            alertModal={true}
            isCenter={true}
            showTemplate={true}>
            <TariffModal
              charge={tariffData}
              type="job"
              closeModal={this.onCloseTariffModal}
            />
          </PegModal>
        </div>
        <div id="priceSummaryModal">
          <PegModal
            isOpen={showPriceSummaryModal}
            alertModal={true}
            isCenter={true}
            showTemplate={true}>
            <PriceSummary
              onClose={this.onClosePriceSummary}
              jobDetail={jobDetail}
              isPriceSummaryPosting={isPriceSummaryPosting}
              sendPricingSummary={(pricingSummary: any) => this.sendPricingSummary(pricingSummary)}
            />
          </PegModal>
        </div>
        <div id="validPartyModal">
          <PegModal
            isOpen={showPartyValidModal}
            alertModal={true}
            showTemplate={true}
            isCenter={true}>
            <div style={{ overflow: 'hidden', padding: 12 }}>
              <QuotationAlert>Validating party status...</QuotationAlert>
              <Loader
                size={100}
                color={'blue'}
              />
            </div>
          </PegModal>
        </div>
        <div id="filterColumnModal">
          <PegModal
            isOpen={showFilterModal}
            closeModal={this.onClickFilterColumn}
            alertModal={true}
            isCenter={true}
            showTemplate={true}>
            <Preferences
              details={jobDetail}
              type={'job'}
              column={columnPreference}
              onCloseFilterModal={this.onClickFilterColumn}
              onSavePreferences={(list: ColumnPreferences) => this.onSavePreferences(list)}
            />
          </PegModal>
        </div>
        <PegModal
          isOpen={showUndoModal}
          closeModal={this.onUndoModalCancel}
          tertiaryAction={this.onUndoModalConfirm}
          modalTitle={"Undo Changes"}
          buttonText={"Clear"}
          modalContent={"Are you sure to Undo the changes"}
        />
      </JobServices>
    )
  }
}

export default JobGrid

