import styled from "styled-components";

interface IStylesProps {
  isHeader?: boolean;
  isService?: boolean;
  isSticky?: boolean;
  groupColor?: any;
  colour?: any;
  showBorder?: boolean;
  value?: any;
}

export const Row = styled.div<IStylesProps>`
display: flex;
font-size: 12px;
font-weight: ${(props) => (props.isHeader ? '500' : '400')};
justify-content: center;
align-items: center;
min-height: 36px;
background-color: ${(props) => (props.isHeader ? '#f4f5f8' : props.isService ? "#FFF" : '#FFF')};
border-bottom: 1px solid #d8d8d8;
border-right: 1px solid #d8d8d8;
`;

export const RowData = styled(Row)`
background-color: ${(props) => (props.groupColor)};
height: ${(props) => (props.isHeader ? '36px' : 'auto')};
`;
export const OverviewHeader = styled.div`
  display: flex;
`;
export const HeaderView = styled.div`
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index:100;
`;
export const Grid = styled.div`
  border: 1px solid #D8D8D8;
  border-radius: 4px;
  padding-bottom: 16px;
  overflow: auto;
  height: calc(100vh - 205px);
`;
export const JobServices = styled.div`
background-color: #FFF;
padding: 16px 12px; 
position: relative;
`;

export const Overview = styled.div`
display: flex;
`;

export const NameHeader = styled(RowData)`
min-width: 200px;
`;

export const TextFieldContainer = styled(RowData)`
padding-left: 8px;
border: 0;
background-color: ${(props) => (props.groupColor)};
`;

export const ID = styled(RowData)`
min-width: 60px;
z-index: 1;
position: sticky;
left: 0px;
`;
export const Delete = styled(RowData)`
min-width: 35px;
`;
export const Add = styled(RowData)`
min-width: 35px;
`;
export const Expand = styled(RowData)`
min-width: 35px;
`;
export const Detail = styled(RowData)`
min-width: 35px;
`;
export const ExternallyEdited = styled(RowData)`
min-width: 35px;
`;

export const Name = styled(RowData)`
min-width: 200px;
max-width: 200px;
z-index: 1;
position: sticky;
left: 60px;
`;
export const BillingParty = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;

export const BillingPartyAddress = styled(RowData)`
  min-width: 200px;
  max-width: 200px;
  padding-left: 5px;  
  display: flex;
  justify-content: ${(props) => (props.isHeader ? 'center' : 'flex-start')};
  white-space: nowrap;
`;

export const CustomerService = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;

//DateRange
export const StartDate = styled(RowData)`
  min-width: 220px;
  max-width: 220px;
`;
export const EndDate = styled(RowData)`
  min-width: 220px;
  max-width: 220px;
`;

//Revenue
export const Quantity = styled(RowData)`
min-width: 100px;
max-width: 100px;
`;
export const ReceivedQuantity = styled(RowData)`
  min-width: 100px;
  max-width: 100px;
`;
export const UOM = styled(RowData)`
min-width: 150px;
`;
export const UnitPrice = styled(RowData)`
  min-width: 100px;
  max-width: 100px;
`;
export const Currency = styled(RowData)`
min-width: 80px;
justify-content: ${(props) => (props.isHeader ? 'center' : 'flex-start')};
`;
export const Amount = styled(RowData)`
min-width: 100px;
max-width: 100px;
`;
export const BillingCurrency = styled(RowData)`
min-width: 86px;
justify-content: ${(props) => (props.isHeader ? 'center' : 'flex-start')};
`;
export const ExRate = styled(RowData)`
min-width: 100px;
max-width: 100px;
`;
export const Tax = styled(RowData)`
min-width: 150px;
max-width: 150px;
`;
export const SalesTaxAmt = styled(RowData)`
  min-width: 125px;
  max-width: 125px;
`;
export const SalesInvoice = styled(RowData)`
min-width: 200px;
max-width: 200px;
word-break: break-all
`;
export const BillAmount = styled(RowData)`
min-width: 100px;
max-width: 100px;
`;

export const AdjBillAmount = styled(RowData)`
min-width: 100px;
max-width: 100px;
`;

//cost
export const Supplier = styled(RowData)`
min-width: 200px;
`;
export const CostUOM = styled(RowData)`
  min-width: 150px;
  max-width: 150px;
`;
export const CostQty = styled(RowData)`
  min-width: 100px;
  max-width: 100px;
`;
export const EstUnitCost = styled(RowData)`
  min-width: 100px;
  max-width: 100px;
`;
export const EstCurrency = styled(RowData)`
min-width: 80px;
max-width: 80px;
justify-content: ${(props) => (props.isHeader ? 'center' : 'flex-start')};
`;
export const EstExRate = styled(RowData)`
min-width: 100px;
`;
export const EstTaxType = styled(RowData)`
min-width: 150px;
max-width: 150px;
`;
export const EstTax = styled(RowData)`
  min-width: 100px;
  max-width: 100px;
`;
export const EstAmt = styled(RowData)`
min-width: 100px;
max-width: 150px;
`;
export const EstAmtTax = styled(RowData)`
min-width: 150px; 
max-width: 150px;
`;
export const CostVariance = styled(RowData)`
min-width: 120px; 
max-width: 120px; 
`;
export const IsBillable = styled(RowData)`
min-width: 100px;
max-width: 100px;
`;
export const BillingStatus = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;
export const BillingOptions = styled(RowData)`
min-width: 150px;
max-width: 150px;
`;
export const PaymentMethod = styled(RowData)`
min-width: 150px;
max-width: 150px;
`;

// sr request

export const SrNo = styled(RowData)`
min-width: 175px;
`;
export const SrStatus = styled(RowData)`
min-width: 100px;
`;
export const SrRating = styled(RowData)`
min-width: 100px;
`;
export const ServiceStatus = styled(RowData)`
min-width: 150px;
max-width: 150px;
`;
export const SrStartTime = styled(RowData)`
min-width: 150px;
max-width: 150px;
`;
export const SrEndTime = styled(RowData)`
min-width: 150px;
max-width: 150px;
`;
export const SupplierInvoice = styled(RowData)`
min-width: 150px;
max-width: 150px;
`;

// operating income 
export const OpEstimated = styled(RowData)`
min-width: 150px;
max-width: 150px;
`;
export const OpActuals = styled(RowData)`
min-width: 150px;
max-width: 150px;
`;
export const OperatingIncome = styled(RowData)`
min-width: 150px;
max-width: 150px;
`;


// eye_share
export const EsRefNo = styled(RowData)`
min-width: 150px;
max-width: 150px;
`;

export const SupplierInvoiceDetail = styled(RowData)`
min-width: 150px;
max-width: 150px;
display: flex;
flex-wrap: wrap;
height: auto;
`;

export const EsActualCost = styled(RowData)`
min-width: 120px;
height: auto;
`;

export const ActualCostExRate = styled(RowData)`
min-width: 120px;
height: auto;
`;


//credit_note
export const CreditNote = styled(RowData)`
min-width: 150px;
max-width: 150px;
`;
export const CreditAmt = styled(RowData)`
min-width: 100px;
max-width: 100px;
`;
export const CreditNoteRef = styled(RowData)`
min-width: 150px;
max-width: 150px;
display: flex;
flex-wrap: wrap;
height: auto;
`;

// cash voucher
export const IsCashVoucherNeeded = styled(RowData)`
  min-width: 150px;
  max-width: 150px;
`;

export const VoucherType = styled(RowData)`
  min-width: 150px;
  max-width: 150px;
`;

export const VoucherNo = styled(RowData)`
min-width: 160px;
max-width: 160px;
display: flex;

a {
  padding 0px 8px;
}
span {
  margin-left: auto;
  padding 0px 8px;
}
`;


// remarks
export const InternalRemarks = styled(RowData)`
min-width: 177px;
`;

export const ExternalRemarks = styled(RowData)`
min-width: 177px;
`;

export const BillingRemarks = styled(RowData)`
min-width: 177px;
`;

export const EstExTax = styled(RowData)`
min-width: 150px;
`;

export const LocalAmount = styled(RowData)`
min-width: 100px;
max-width: 100px;
`;
export const EstQuantity = styled(RowData)`
min-width: 100px;
max-width: 100px;
`;
export const EstUOM = styled(RowData)`
min-width: 200px;
`;

export const EstPurchaseTax = styled(RowData)`
min-width: 150px;
`;
export const SrSerStatus = styled(RowData)`
min-width: 150px;
`;
export const SrEstimated = styled(RowData)`
min-width: 150px;
`;
export const SrActuals = styled(RowData)`
min-width: 100px;
max-width: 100px;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis
`;
export const SrReferenceNo = styled(RowData)`
min-width: 150px;
`;
export const SrActualCost = styled(RowData)`
min-width: 150px;
`;
export const SrCost = styled(RowData)`
min-width: 150px;
`;
export const BackToBack = styled(RowData)`
min-width: 100px;
`;
export const Group = styled.div<IStylesProps>`
z-index: ${(props) => (props.isSticky ? '1' : '')};
position: ${(props) => (props.isSticky ? 'sticky' : '')};
left: ${(props) => (props.isSticky ? '0px' : '')};
background-color: #f4f5f8;
`;

export const SecondaryGroup = styled(Group)`
left: ${(props) => (props.isSticky ? '60px' : '')};
`;

export const GroupName = styled.div<IStylesProps>`
height: 32px;
display: flex;
justify-content: center;
align-items: center;
border-bottom: ${(props) => (props.groupColor ? `3px solid ${props.groupColor}` : `3px solid '#f4f5f8'`)};
`;
export const Flex = styled.div<IStylesProps>`
display: flex;
`;
export const GridOptions = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
`;

export const Wrapper = styled.section`            
display: flex;
border-radius: 4px;
border: 1px solid #D8D8D8;
overflow: auto;
margin-right: 8px;
background-color: #f4f5f8;  
`;

export const SummaryDetail = styled.div`
display: flex;
position: relative;
flex-flow: column nowrap;
flex: 0 1 auto;
padding: 10px 20px;              
overflow: hidden;
min-width: 180px;
& + &::after {
  content: '';
  position: absolute;
  left: 0;
  top: 10%;
  height: 80%;
  border-left: 1px solid #D8D8D8;
}                
`;

export const Typography = styled.h4<IStylesProps>`
white-space: wrap;
font-size: 10px;
font-weight: 500;
color: #222428;
text-align: right;
text-transform: uppercase;
`;
export const Value = styled.h2<IStylesProps>`
padding: 2px 0px;
border-bottom-color: ${(props) => ((isNaN(props.value) || props.value == 0) ? '#92949c' : props.value < 0 ? '#d40606' : '#04922a')};
border-bottom-width: 4px;
border-bottom-style: solid;
white-space: wrap;
text-align: right;
font-weight: 500;
`;

export const GroupOptions = styled.div<IStylesProps>`
  padding: 2px 4px;
  width: 140px;
  height: 28px;
  margin-right : 12px;
  border: 1px solid #CCC;
  background-color: ${(props) => (props.colour ? props.colour : '')};
  cursor: pointer;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  span{
    padding: 0px 4px;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
  }
`;
export const MarginLeft = styled.div<IStylesProps>`
display: flex;
margin-left: auto;
align-items: center;
span{
  color: red;
  margin-right: 12px;
  font-size: 14px;
}
`;
export const LoaderOverview = styled.div<IStylesProps>`
display: flex;
justify-content: center;
align-items: center;
height: calc(100vh - 75px);
`;
export const TemplateOverview = styled.div<IStylesProps>`
display: flex;
width: 79vw;
height: 65vh;
border-radius: 4px;
border: 1px solid #d8d8d8;
`;
export const TemplateOptions = styled.div<IStylesProps>`
min-width: 25%;
border-right: 1px solid #d8d8d8;
overflow: scroll;
margin-top: 26px;
`;

export const SelectAllTemplates = styled.div<IStylesProps>`
position: absolute;
top: 22px;
padding-left: 8px;
border-bottom: 1px solid #d8d8d8;
width: 24%;
background-color: #d8d8d8;
`;

export const OptionsLoading = styled.div<IStylesProps>`
display: flex;
justify-content: center;
align-items: center;
`;
export const TemplateSection = styled.div<IStylesProps>`
width: 75%; 
`;
export const GridView = styled.div<IStylesProps>`
height: calc(65vh - 70px);
margin: 12px;
width: 96%;
overflow: scroll;
`;
export const GridViewLoading = styled(GridView) <IStylesProps>`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;
export const Footer = styled.div<IStylesProps>`
display: flex;
`;
export const ListName = styled.div<IStylesProps & { selected?: boolean; }>`
font-size: 12px;
border-bottom: 1px solid #d8d8d8;
padding: 8px;
padding-bottom: 4px;
cursor: pointer;
background-color: ${(props) => (props.selected ? '#DBF3FF' : '')};
`;

export const ListCheckbox = styled.input`
margin-right: 10px;
position: relative;
top: 2px;
`

export const NumberToColor = styled.div<IStylesProps>`
font-size: 18px;
`

export const Green = styled.div<IStylesProps>`
color: green;
`;
export const Red = styled.div<IStylesProps>`
color: red;
`;

export const TemplateModalOverview = styled.div<IStylesProps>`
overflow: scroll;
padding-bottom: 12px;
`;

export const Input = styled.input`
cursor: pointer;
margin-right: 8px !important;
`;

export const ArrowRight = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid #d8d8d8;
  cursor: pointer;
  border-radius: 4px;
  position: absolute;
  z-index: 1000;
  top: 45%;
  right: 12px;
  background-color: black;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ArrowLeft = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid #d8d8d8;
  cursor: pointer;
  border-radius: 4px;
  position: absolute;
  z-index: 1000;
  top: 45%;
  background-color: black;
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoaderErrorMessage = styled.div`
  position: absolute;
  margin-top: -100px;
  color: red;
  font-size: 1.2em;
  font-weight: 400;
`