import { useState, useEffect } from 'react';
import {
	Wrapper,
	SummaryDetail,
	Typography,
	Value,
	Green,
	Red,
	Flex,
	NumberToColor
} from '../Jobs.styles';

import { convertToFormattedString } from 'Utils/Generic';
import { VerLabel } from 'style';
import { calculateCost } from 'Utils/JobGrid';

type Props = {
	services: any;
	jobDetails: any;
	userDetails: any;
};
const SummaryDetails = (props: Props) => {

	const { services, jobDetails, userDetails } = props;
	const { CurrencyDecimalPlacesAutoCalculation } = userDetails
	let autoDecimal = CurrencyDecimalPlacesAutoCalculation ? parseInt(CurrencyDecimalPlacesAutoCalculation) : 2;
	const [totalOperatingIncome, setTotalOperatingIncome] = useState<number>(0);
	const [totalOperatingIncomePercent, setTotalOperatingIncomePercent] = useState<number>(0);
	const [totalNetPrice, setTotalNetPrice] = useState<number>(0);
	const [totalCost, setTotalCost] = useState<number>(0);
	const [salesTaxAmount, setSalesTaxAmount] = useState<number>(0);

	useEffect(() => {
		const asyncFn = async () => {

			let operatingIncome: number = 0;
			let totalNetPrice: number = 0;
			let totalCost: number = 0;
			let salesTaxAmount: number = 0;
			let operatingIncomePercent = 0;

			services.forEach((service: any) => {

				service.charges.forEach((charge: any) => {
					operatingIncome += charge.OperatingIncomeActual;

					const netPrice = charge.Quantity * charge.UnitPrice * charge.PriceExchangeRate;
					totalNetPrice += netPrice;

					const cost = calculateCost(charge);
					totalCost += cost;

					salesTaxAmount += charge.PriceTaxAmount * charge.PriceExchangeRate;
				})
			})

			if (services?.length > 0 && operatingIncome) {
				if (totalCost) {
					operatingIncomePercent = (operatingIncome / totalCost) * 100;
				} else {
					operatingIncomePercent = 100;
				}
			}

			setTotalOperatingIncomePercent(operatingIncomePercent);
			setTotalOperatingIncome(operatingIncome);
			setTotalNetPrice(totalNetPrice);
			setTotalCost(totalCost);
			setSalesTaxAmount(salesTaxAmount);

		}
		asyncFn()
	}, [services])

	return (
		<Flex>
			<Wrapper>

				{(jobDetails?.IsMaster === false &&
					<SummaryDetail>
						<Typography value={totalNetPrice}>Total Net Price</Typography>
						<Value value={totalNetPrice}>
							<NumberToColor value={totalNetPrice}>
								{`${convertToFormattedString(totalNetPrice, autoDecimal)} ${jobDetails?.CurrencyCode}`}
							</NumberToColor>
						</Value>
					</SummaryDetail>)
				}
			</Wrapper>
			<Wrapper>
				<SummaryDetail>
					<Typography value={totalCost}>Total Cost</Typography>
					<Value value={totalCost}>
						<NumberToColor value={totalCost}>
							{`${convertToFormattedString(totalCost, autoDecimal)} ${jobDetails?.CurrencyCode}`}
						</NumberToColor>
					</Value>

				</SummaryDetail>
			</Wrapper>
			<Wrapper>
				{(jobDetails?.IsMaster === false && <SummaryDetail>
					<Typography value={totalOperatingIncome}>Operating Income</Typography>
					<Value value={totalOperatingIncome}>
						<NumberToColor value={totalOperatingIncome}>
							{`${convertToFormattedString(totalOperatingIncome, autoDecimal)} ${jobDetails?.CurrencyCode}`}
						</NumberToColor>
					</Value>

				</SummaryDetail>)}
			</Wrapper>
			<Wrapper>
				{(jobDetails?.IsMaster === false && <SummaryDetail>
					<Typography value={totalOperatingIncome}>Operating Income %</Typography>
					<Value value={totalOperatingIncomePercent}>
						<NumberToColor value={totalOperatingIncomePercent}>
							{`${convertToFormattedString(totalOperatingIncomePercent, autoDecimal)} %`}
						</NumberToColor>
					</Value>

				</SummaryDetail>)}
			</Wrapper>
			<Wrapper>
				<SummaryDetail>
					<Typography value={salesTaxAmount}>Sales Tax Amount</Typography>
					<Value value={salesTaxAmount}>
						<NumberToColor value={salesTaxAmount}>
							{`${convertToFormattedString(salesTaxAmount, autoDecimal)} ${jobDetails?.CurrencyCode}`}
						</NumberToColor>
					</Value>

				</SummaryDetail>
			</Wrapper>
			<VerLabel>v {process.env.REACT_APP_VERSION_INFO} {process.env.REACT_APP_ENV_NAME}</VerLabel>
		</Flex>
	)
};

export default SummaryDetails;
