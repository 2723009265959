
import { ReactComponent as DeleteIcon } from 'Assets/delete-item.svg'
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import ToolTip from 'Controls/ToolTip/ToolTip';
import Dropdown from 'Controls/Dropdown';
import InputBox from 'Controls/Input/PegInputBox';
import {
  setChargeValue,
  setTariffDetails,
  setServiceValue,
  formatQuoteBillingPartyAddress
} from 'Utils/QuotationGrid';
import PegModal from 'Controls/PegModal';
import {
  OverviewHeader,
  Overview,
  Group,
  // list
  ID,
  Delete,
  Add,
  Expand,
  NameHeader,
  EndDate,
  StartDate,
  CustomerService,
  BillingParty,
  BillingPartyName,
  BillingPartyAddress,
  OperatingIncome,
  UOM,
  Quantity,
  UnitPrice,
  PriceCurrency,
  CostCurrency,
  EstTaxType,
  EstTax,
  EstAmt,
  EstAmtTax,
  UnitCost,
  TotalCost,
  TotalCostLocal,
  BackToBack,
  Supplier,
  TaxType,
  TaxRate,
  NetPrice,
  NetPriceLocal,
  NetPriceSelling,
  InternalRemarks,
  ExternalRemarks,
  Red,
  Green,
  Detail,
  TextFieldContainer,
  SecondaryGroup
} from '../quote.styles'
import { checkforNonNegativeValue, formatBillingPartyCode, splitBillingPartyCode } from 'Utils/Generic';
import axios from 'axios';
import { AiOutlineEye } from 'react-icons/ai';
import { BiEditAlt, BiErrorAlt } from 'react-icons/bi';

import { Color } from 'Utils/Color';
import { getCustomOption, getValueContainer } from 'Components/BillingParty/DropdownComponents';
import { IQuotationBillingParty } from 'Model/QuotationGrid/types';
import { ColumnPreferences } from 'Model/Common/ColumnPreferences';
import { convertPricesAndTaxes } from 'Utils/JobGrid';
import { UserDefault } from 'Model/User/UserDefault';
import { QuotationCharge } from 'Model/QuotationGrid/QuotationCharge';
import { IProduct, IQBillingParties, IQuotationDetails, onShowTariffFunc, onUpdateChargeFunc, onUpdateServiceFunc } from 'Model/QuotationGrid/types';
import { QuotationService } from 'Model/QuotationGrid/QuotationService';
import { IChargeName, IDropdownOptions, onDeleteChargeFunc } from 'Model/Common/types';

type Props = {
  userDetails: UserDefault;
  chargeList: IChargeName[];
  chargeIndex: number;
  serviceIndex: number;
  charge: QuotationCharge;
  service: any;
  serviceAndCharges: any;
  uomList: any;
  taxList: any;
  disableRevenue: boolean;
  disableCost: boolean;
  disableOperatingIncome: boolean;
  billingParties: IQBillingParties[];
  selectedProduct: IProduct;
  quotationDetails: IQuotationDetails;
  isReadOnly: boolean;
  onUpdateCharge: onUpdateChargeFunc;
  onUpdateService: onUpdateServiceFunc;
  onDeleteCharge: onDeleteChargeFunc;
  onShowTariff: onShowTariffFunc;
  editCharge: boolean;
  showTariffModal: boolean;
  onTariffModalConfirm: () => void;
  onTariffModalCancel: () => void;
  onTariffModalOpen: () => void;
  setShowTarriffModal: (value: boolean) => void;
  columnPreference: ColumnPreferences;
  updateChargeValidation: (validationKey: string, chargeId: string, value: boolean) => void;
  addToEditable: (index: number) => void;
  removeFromEditable: (index: number) => void;
};

const Charge = (props: Props) => {
  const {
    charge,
    service,
    serviceAndCharges,
    chargeList,
    uomList,
    taxList,
    billingParties,
    selectedProduct,
    onUpdateCharge,
    chargeIndex,
    serviceIndex,
    onDeleteCharge,
    quotationDetails,
    disableRevenue,
    disableCost,
    disableOperatingIncome,
    userDetails,
    onShowTariff,
    onUpdateService,
    onTariffModalConfirm,
    onTariffModalCancel,
    onTariffModalOpen,
    editCharge,
    showTariffModal,
    isReadOnly,
    columnPreference,
    updateChargeValidation,
    addToEditable,
    removeFromEditable
  } = props

  const {
    CurrencyDecimalPlaces,
    CurrencyDecimalPlacesAutoCalculation
  } = userDetails

  let decimalPlace = CurrencyDecimalPlaces ? parseInt(CurrencyDecimalPlaces) : 2;
  let autoDecimal = CurrencyDecimalPlacesAutoCalculation ? parseInt(CurrencyDecimalPlacesAutoCalculation) : 2;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [customerService, setCustomerService] = useState<IDropdownOptions[]>([])
  const [billingPartiesData, setBillingPartiesData] = useState<any[]>([]);

  const styleClass = "text-left text-xs font-medium text-gray-500 tracking-wider bg-gray-50";
  const inputStyle = {
    border: 'none',
    height: '34px',
    borderRadius: '0px',
    fontSize: '12px'
  }
  let netPrice = 0;
  let netPriceDiscount = 0;
  let priceTax = 0;
  let netPriceDiscountTax = 0;
  let netLocalPrice = 0;
  let netSellingPrice = 0;
  let totalCost = 0;
  let totalCostLocal = 0;
  let operatingIncome = 0;

  if (charge?.Quantity && charge?.UnitPrice) {
    netPrice = charge?.Quantity * charge?.UnitPrice
  }

  netPriceDiscount = netPrice;

  if (charge?.PriceTaxPercent) {
    priceTax = netPrice * (charge?.PriceTaxPercent / 100);
    netPriceDiscountTax = priceTax + netPriceDiscount
  } else {
    priceTax = 0;
    netPriceDiscountTax = netPriceDiscount
  }

  if (service?.PriceExchangeRate) {
    netLocalPrice = netPriceDiscountTax * service?.PriceExchangeRate;
  } else {
    netLocalPrice = netPriceDiscountTax;
  }

  if (charge?.Quantity && charge?.UnitCost) {
    totalCost = charge?.Quantity * charge?.UnitCost
  }

  if (service.CostExchangeRate) {
    totalCostLocal = totalCost * service.CostExchangeRate
  } else {
    totalCostLocal = totalCost;
  }

  if (netPrice && service?.PriceExchangeRate) {
    operatingIncome = (netPrice * service?.PriceExchangeRate) - totalCostLocal;
  }

  if (service?.PriceSellingExRate) {
    if (quotationDetails.BaseCurrencyCode == service.PriceCurrencyCode) {
      netSellingPrice = netPriceDiscountTax * (1 / service.PriceSellingExRate);
    } else {
      netSellingPrice = netPriceDiscountTax * service.PriceSellingExRate;
    }
  }

  useEffect(() => {
    if (chargeList.length && charge.Name) {
      let selectedCharge = chargeList.find((item: IChargeName) => item.code === charge.Code)

      setCustomerService(selectedCharge?.GSServices as IDropdownOptions[])

      const chargeHasCustomerServices = !!selectedCharge?.GSServices?.length;
      updateChargeValidation("CustomerService", charge.Code, chargeHasCustomerServices)

      const hasMissingDolphinCodes = !charge.Id && !(selectedCharge?.revenueServiceCode && selectedCharge?.costServiceCode);

      setDolphinCodeValidation(hasMissingDolphinCodes)
      updateChargeValidation("dolphinCode", charge.Code, hasMissingDolphinCodes)
    }
  }, [chargeList, charge.Name])

  useEffect(() => {
    if (!charge?.Id && !charge.GSServiceName) {
      let defaultCustomerService = null

      if (customerService?.length === 1) {
        defaultCustomerService = customerService[0]
      }

      const chargeObj = setChargeValue(charge, selectedProduct, 'customerService', defaultCustomerService)
      onUpdateCharge(chargeObj, chargeIndex, serviceIndex, selectedProduct)

    }
  }, [customerService])


  useEffect(() => {
    if (charge?.TariffDetails &&
      charge?.TariffDetails?.IsActive &&
      charge?.TariffDetails?.IsTariffErrored === false) {
      removeFromEditable(chargeIndex)
    } else {
      addToEditable(chargeIndex)
    }
  }, [charge?.TariffDetails?.IsActive, charge])

  useEffect(() => {
    const asyncFn = async () => {
      if (!billingParties) return;

      if (billingParties.length === 1 && !charge.BillingPartyCode) {
        let chargeObj = setChargeValue(charge, selectedProduct, 'billingParty', billingParties[0])
        onUpdateCharge(chargeObj, chargeIndex, serviceIndex, selectedProduct)
      }

      let bpData: any[] = [];
      billingParties.forEach((bp: any) => {
        bpData.push({
          ...bp,
          Code: bp.AddressId ? formatBillingPartyCode(bp.Code, bp.AddressId[0]) : bp.Code,
        });
      })
      if (bpData.length) setBillingPartiesData(bpData);
    }
    asyncFn()
  }, [billingParties])

  useEffect(() => {
    const asyncFn = async () => {
      if (charge?.PriceTaxPercent) {
        netPriceDiscountTax = priceTax + netPriceDiscount
      }
    }
    asyncFn();
  }, [charge?.PriceTaxPercent])

  useEffect(() => {
    const asyncFn = async () => {
      if (charge?.PriceTaxPercent) {
        priceTax = netPrice * (charge?.PriceTaxPercent / 100);
      }
    }
    asyncFn()
  }, [charge?.UnitPrice, charge?.PriceTaxPercent])

  useEffect(() => {
    if (!!service.BackToBack) {
      let chargeObj = setChargeValue(charge, selectedProduct, "unitPrice", charge.UnitCost)
      onUpdateCharge(chargeObj, chargeIndex, serviceIndex, selectedProduct)
    }
  }, [charge.UnitCost, service.BackToBack])

  const onChargeDelete = useCallback(() => {
    setShowDeleteModal(true)
  }, [chargeIndex]);

  const onDeleteModalCancel = useCallback(() => {
    setShowDeleteModal(false)
  }, [chargeIndex]);

  const onDeleteModalConfirm = useCallback(() => {
    onDeleteCharge(serviceIndex, chargeIndex)
    setShowDeleteModal(false)
  }, [chargeIndex]);

  const [dolphinCodeValidation, setDolphinCodeValidation] = useState(false);

  const onChangeDropdownValue = useCallback(async (event: any, id: string) => {

    let chargeObj = setChargeValue(charge, selectedProduct, id, event)

    if (id === "chargeName") {

      getVatTaxDetails();

    }

    if (chargeObj?.TariffDetails?.IsActive) {
      chargeObj = setTariffDetails(userDetails, chargeObj)
    }
    if (id === 'customerService') {
      if (!event) {
        let servObj = setServiceValue(service, id, {
          code: "",
          name: ""
        })
        onUpdateService(servObj, serviceIndex, selectedProduct)
      } else {
        let servObj = setServiceValue(service, id, event)
        onUpdateService(servObj, serviceIndex, selectedProduct)
      }
    }

    onUpdateCharge(chargeObj, chargeIndex, serviceIndex, selectedProduct)

  }, [charge, service]);

  const onChangeInputValue = useCallback((event: any) => {
    if (event.target.type === 'number') {
      var t = event.target.value;
      event.target.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), decimalPlace + 1)) : t;
    }
    let chargeObj = setChargeValue(charge, selectedProduct, event.target?.id, event.target.value)
    if (chargeObj?.TariffDetails?.IsActive) {
      chargeObj = setTariffDetails(userDetails, chargeObj)
    }
    onUpdateCharge(chargeObj, chargeIndex, serviceIndex, selectedProduct)
  }, [charge]);

  const getVatTaxDetails = async () => {
    let servicesFiltered: QuotationService;
    servicesFiltered = selectedProduct?.ServiceAndCharges[serviceIndex].service;
    let chargesFiltered = selectedProduct?.ServiceAndCharges[serviceIndex].charges[chargeIndex]
    let newServiceData = {
      service: {
        ...servicesFiltered,
      },
      charges: [chargesFiltered]
    }
    await axios.post(`/qtnService-mapVatTaxDetails`, {
      QuotationProductId: selectedProduct?.ProductDetails?.QuotationProductId,
      ServiceAndCharges: [newServiceData]
    }).then((response) => {
      if (response?.data) {
        let chargeObj = setChargeValue(charge, selectedProduct, 'vatType', response.data.data[0]?.vatType)
        onUpdateCharge(chargeObj, chargeIndex, serviceIndex, selectedProduct)
      }
    })
  }

  const { selectedBPAddress, selectedBPCode, selectedBPName } = useMemo(() => {
    let selectedBPAddress = null;
    let selectedBPCode = null;
    let selectedBPName = null;
    let selectedBillingParty;

    if (charge.BillingPartyCode) {
      if (billingPartiesData.length === 1) {
        selectedBillingParty = billingPartiesData[0];
      } else {
        selectedBillingParty = billingPartiesData.find((bp: any) => {
          let bpCode = splitBillingPartyCode(bp.Code);

          let match = bpCode[0] === charge.BillingPartyCode;
          if (match && charge.AddressFk) {
            match = bp.AddressId[0] === charge.AddressFk;
          }

          return match;
        });
      }
    } else {
      selectedBillingParty = billingPartiesData.find((bp: any) => {
        return bp.Node === quotationDetails.CustomerName;
      });
    }

    if (selectedBillingParty) {
      selectedBPAddress = formatQuoteBillingPartyAddress(selectedBillingParty);
      selectedBPCode = selectedBillingParty.Code;
      selectedBPName = selectedBillingParty.Node;

      if (!charge.BillingPartyCode) {
        let chargeObj = setChargeValue(charge, selectedProduct, "billingParty", selectedBillingParty);
        onUpdateCharge(chargeObj, chargeIndex, serviceIndex, selectedProduct);
      }

    }

    return { selectedBPAddress, selectedBPCode, selectedBPName };
  }, [charge.BillingPartyCode, charge.AddressFk, billingPartiesData]);

  const {
    // operatingIncome,
    estimatedTaxAmount,
    estimatedAmount,
    purchaseTaxAmount,
    // billAmount,
    // adjBillAmount,
    // billTax,
    // amountValue,
  } = convertPricesAndTaxes(quotationDetails.CurrencyCode, serviceAndCharges, chargeIndex);

  const ValueContainer = getValueContainer<IQuotationBillingParty>();
  const CustomOption = getCustomOption<IQuotationBillingParty>(formatQuoteBillingPartyAddress);

  const chargeDolphinCodeStyle = dolphinCodeValidation ? {
    border: '1px solid red'
  } : {};

  const [internalRemarks, setInternalRemarks] = useState(charge.InternalRemarks);
  const [externalRemarks, setExternalRemarks] = useState(charge.ExternalRemarks);

  return (
    <OverviewHeader className={styleClass} data-testid="quotationGridCharges">
      <Overview>
        <ID>
          {serviceIndex + 1}.{chargeIndex + 1}
        </ID>
        <ToolTip
          id="deleteToolTip"
          content={'Delete'}
          placement={'right'}
          animation={'scale-subtle'}
          arrow={true}
          duration={200}
          delay={[75, 0]}
        >
          <Delete>
            <DeleteIcon
              style={!isReadOnly ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: 0.5 }}
              onClick={() => !isReadOnly && onChargeDelete()}
              data-testid="deleteCharge"
            />
          </Delete>
        </ToolTip>

        <Add>
          {
            charge?.TariffDetails?.IsActive &&
            <>
              {
                !charge?.TariffDetails?.IsTariffErrored ?
                  <>
                    {!isReadOnly ? <BiEditAlt
                      onClick={() => !isReadOnly && onTariffModalOpen()}
                      className="icon-style-md" /> : null}
                  </>
                  :
                  <>
                    <ToolTip
                      id="applyTariffToolTip"
                      content={charge?.TariffDetails?.ErrorMessage}
                      placement={'right'}
                      animation={'scale-subtle'}
                      arrow={true}
                      duration={200}
                      delay={[75, 0]}
                    >
                      <button>
                        <BiErrorAlt
                          data-tip
                          data-for='errorIcon'
                          style={{ color: 'red' }}
                          className="icon-style-md" />
                      </button>
                    </ToolTip>
                  </>
              }
            </>
          }
        </Add>
        <Expand />
        <Detail>
          {charge?.TariffDetails?.IsActive &&
            <>
              {isReadOnly ? <AiOutlineEye
                onClick={() => onShowTariff(charge)}
                className="icon-style-md" /> : null}
            </>
          }
        </Detail>
        <SecondaryGroup isSticky={true} data-tooltip-id={"chargeName" + serviceIndex + chargeIndex}>
          <NameHeader>
            <Dropdown
              defaultValue={charge.Name}
              onChangeDropdown={onChangeDropdownValue}
              label={'name'}
              dropdownOptions={chargeList}
              dropdownLabel={'name'}
              dropdownValue={'name'}
              style={{ width: 198, ...chargeDolphinCodeStyle }}
              placeHolder="Select Charge"
              dropdownMap={'services'}
              isMenuPortalTarget={'true'}
              domId={'stickyPosition'}
              removeBorder={true}
              id="chargeName"
              removeIsClear={true}
              isMandatory={true}
              disabled={isReadOnly || !editCharge}
            />
          </NameHeader>
          {dolphinCodeValidation && <ReactTooltip
            id={"chargeName" + serviceIndex + chargeIndex}
            place="right"
            variant="error"
          >
            <span>Note that the charge selected has missing dolphin codes.
              <br />Please contact finance/accounts to check and resolve</span>
          </ReactTooltip>}
        </SecondaryGroup>
        {columnPreference['CUSTOMER_SERVICE']?.isActive && <CustomerService>
          {
            charge.Name && customerService?.length ?
              <Dropdown
                defaultValue={charge.GSServiceName}
                id={'customerService'}
                dropdownOptions={customerService}
                dropdownLabel={'name'}
                dropdownValue={'name'}
                appendParams={false}
                useDebounce={true}
                style={{ width: 198 }}
                removeBorder={true}
                placeHolder="Select customer"
                onChangeDropdown={onChangeDropdownValue}
                isMenuPortalTarget={'true'}
                type="charge"
                domId={'stickyPosition'}
                disabled={isReadOnly || !editCharge}
                isMandatory={true}
              />
              :
              <Dropdown
                defaultValue={charge.GSServiceName}
                id={'customerService'}
                dropdownOptions={customerService}
                dropdownLabel={'name'}
                dropdownValue={'name'}
                isLookup={true}
                appendParams={false}
                useDebounce={true}
                style={{ width: 198 }}
                placeHolder="Select customer"
                onChangeDropdown={onChangeDropdownValue}
                isMenuPortalTarget={'true'}
                removeBorder={true}
                type="charge"
                url={`/getAllGssMapping?partyCode=${quotationDetails?.CustomerCode}&company-code=${quotationDetails.CompanyCode}&searchText=`}
                domId={'stickyPosition'}
                disabled={isReadOnly || !editCharge}
              />
          }
        </CustomerService>}
        {columnPreference['BILLING_PARTY']?.isActive &&
          <BillingParty>
            {billingPartiesData.length > 1 ?
              <Dropdown
                customComponents={{ Option: CustomOption, ValueContainer }}
                defaultValue={selectedBPCode}
                id={'billingParty'}
                dropdownOptions={billingPartiesData}
                dropdownLabel={'Node'}
                dropdownValue={'Code'}
                appendParams={false}
                useDebounce={true}
                style={{ width: 198 }}
                dropdownStyle={{ menuPortal: { width: 400 }, control: { boxShadow: "none" } }}
                removeBorder={true}
                removeIsClear={true}
                placeHolder="Select party"
                onChangeDropdown={onChangeDropdownValue}
                isMenuPortalTarget={'true'}
                type="charge"
                domId={'stickyPosition'}
                disabled={isReadOnly || !editCharge}
              /> : <BillingPartyName
                data-tip
                data-tooltip-id={"billingPartyName" + serviceIndex}>
                {selectedBPName}
                {selectedBPName && <ReactTooltip
                  id={"billingPartyName" + serviceIndex}
                >
                  <span>{selectedBPName}</span>
                </ReactTooltip>}
              </BillingPartyName>}
          </BillingParty >}
        {columnPreference['BILLING_PARTY_ADDRESS']?.isActive &&
          <BillingPartyAddress
            data-tip
            data-tooltip-id={"billingPartyAddress" + serviceIndex}
          >
            {selectedBPAddress}
            {selectedBPAddress && <ReactTooltip
              id={"billingPartyAddress" + serviceIndex}
            >
              <span>{selectedBPAddress}</span>
            </ReactTooltip>}
          </BillingPartyAddress>
        }
        {columnPreference['START_DATE']?.isActive && <StartDate />}
        {columnPreference['END_DATE']?.isActive && <EndDate />}
        {
          !disableOperatingIncome && columnPreference['OPERATING_INCOME']?.isActive &&
          <OperatingIncome groupColor={Color.operatingIncome}>
            {operatingIncome < 0 ? <Red> {Math.abs(operatingIncome).toFixed(autoDecimal)}</Red>
              :
              <Green>{operatingIncome.toFixed(autoDecimal)} </Green>}
          </OperatingIncome>
        }
        {
          !disableRevenue &&
          <UOM groupColor={Color.revenue}>
            <Dropdown
              id="UOM"
              defaultValue={charge.UomName}
              type={'charge'}
              label={'name'}
              dropdownOptions={uomList}
              isLookup={false}
              dropdownLabel={'displayName'}
              dropdownValue={'fullName'}
              appendParams={false}
              style={{ 'backgroundColor': `${Color.revenue}`, width: 149 }}
              placeHolder="Select UOM"
              removeIsClear={true}
              removeBorder={true}
              isMandatory={true}
              onChangeDropdown={onChangeDropdownValue}
              isMenuPortalTarget={'true'}
              domId={'stickyPosition'}
              disabled={isReadOnly || !editCharge}
            />
          </UOM>
        }
        {
          !disableRevenue && <Quantity groupColor={Color.revenue}>
            <InputBox
              id="quantity"
              value={charge.Quantity}
              type={'number'}
              isMandatory={true}
              placeholder={'Enter units'}
              onChange={onChangeInputValue}
              disabled={isReadOnly || !editCharge || service.BackToBack}
              onInput={(event: any) => {
                checkforNonNegativeValue(event);
              }}
              style={{ ...inputStyle, 'backgroundColor': `${Color.revenue}` }} />
          </Quantity>
        }
        {
          !disableRevenue && <UnitPrice>
            <InputBox
              id="unitPrice"
              value={charge.UnitPrice}
              type={'number'}
              isMandatory={true}
              placeholder={'Enter units'}
              disabled={isReadOnly || !editCharge || service.BackToBack}
              onInput={(event: any) => {
                checkforNonNegativeValue(event);
              }}
              onChange={onChangeInputValue}
              style={{ ...inputStyle, 'backgroundColor': `${Color.revenue}` }} />
          </UnitPrice>
        }
        {
          !disableRevenue && columnPreference['PRICE_CURRENCY']?.isActive && <PriceCurrency groupColor={Color.revenue}>
            {(service?.PriceCurrencyCode) ?
              <TextFieldContainer groupColor={Color.revenue}>{service?.PriceCurrencyCode}
                {(service?.PriceCurrencyCode !== quotationDetails?.QuoteCurrencyCode) &&
                  <span>
                    - ({service?.PriceSellingExRate} {quotationDetails?.QuoteCurrencyCode})
                  </span>}
              </TextFieldContainer>
              :
              '-'}
          </PriceCurrency>
        }
        {
          !disableRevenue && columnPreference['NET_PRICE']?.isActive && <NetPrice groupColor={Color.revenue}>
            {netPrice.toFixed(autoDecimal)}
          </NetPrice>
        }
        {
          !disableRevenue && columnPreference['TAX_RATE']?.isActive && <TaxType groupColor={Color.revenue}>
            <Dropdown
              id="taxType"
              defaultValue={charge.PriceTaxCode}
              type={'charge'}
              label={'Name'}
              isLookup={false}
              dropdownOptions={taxList}
              dropdownLabel={'Name'}
              dropdownValue={'Code'}
              appendParams={false}
              style={{ 'backgroundColor': `${Color.revenue}`, width: 149 }}
              placeHolder="Tax Type"
              removeBorder={true}
              disabled={isReadOnly || !editCharge}
              onChangeDropdown={onChangeDropdownValue}
              isMenuPortalTarget={'true'}
              domId={'stickyPosition'}
            />
          </TaxType>
        }
        {
          !disableRevenue && columnPreference['TAX_AMT']?.isActive &&
          <TaxRate groupColor={Color.revenue}>
            {priceTax.toFixed(autoDecimal)}
          </TaxRate>
        }
        {
          !disableRevenue && columnPreference['NET_PRICE_LOCAL']?.isActive &&
          <NetPriceLocal groupColor={Color.revenue}>
            {netLocalPrice.toFixed(autoDecimal)}
          </NetPriceLocal>
        }
        {
          !disableRevenue && columnPreference['NET_PRICE_SELLING']?.isActive &&
          <NetPriceSelling groupColor={Color.revenue}>
            {netSellingPrice.toFixed(autoDecimal)}
          </NetPriceSelling>
        }
        {!disableCost && <Supplier groupColor={Color.cost} />}
        {
          !disableCost && <UnitCost groupColor={Color.cost} >
            <InputBox
              id="unitCost"
              value={charge.UnitCost}
              type={'number'}
              min={0}
              checkZeroValue={true}
              isMandatory={true}
              placeholder={'Enter units'}
              onChange={onChangeInputValue}
              style={{ ...inputStyle, 'backgroundColor': `${Color.cost}` }}
              onInput={(event: any) => {
                checkforNonNegativeValue(event);
              }}
              disabled={isReadOnly || !editCharge} />
          </UnitCost>
        }
        {
          !disableCost && columnPreference['COST_CURRENCY']?.isActive && <CostCurrency groupColor={Color.cost}>
            {(service?.CostCurrencyCode) ?
              <TextFieldContainer groupColor={Color.cost}>{service?.CostCurrencyCode}
                {(service?.CostCurrencyCode !== quotationDetails?.BaseCurrencyCode) &&
                  <span>
                    - ({service?.CostExchangeRate} {quotationDetails?.BaseCurrencyCode})
                  </span>}
              </TextFieldContainer>
              :
              '-'}
          </CostCurrency>
        }

        {
          !disableCost && columnPreference['COST_TAX_RATE']?.isActive &&
          <EstTaxType groupColor={Color.cost}>
            <Dropdown
              defaultValue={charge.CostTaxCode}
              id="estimateTax"
              type={'charge'}
              label={'Name'}
              isLookup={false}
              dropdownOptions={taxList}
              dropdownLabel={'Name'}
              dropdownValue={'Code'}
              appendParams={false}
              style={{ 'backgroundColor': `${Color.cost}`, width: 149 }}
              placeHolder="Select Tax Type"
              removeBorder={true}
              onChangeDropdown={onChangeDropdownValue}
              isMenuPortalTarget={'true'}
              domId={'jobChargeView'}
              disabled={charge.IsFromMaster || isReadOnly || !editCharge}
              dataTestId={'UOM'}
            />
          </EstTaxType >
        }
        {
          !disableCost && columnPreference['PURCHASE_TAX']?.isActive &&
          <EstTax groupColor={Color.cost}>{purchaseTaxAmount ? purchaseTaxAmount.toFixed(autoDecimal) : 0}</EstTax>
        }
        {
          !disableCost && columnPreference['AMT']?.isActive &&
          <EstAmt groupColor={Color.cost}>{estimatedAmount.toFixed(autoDecimal)}</EstAmt>
        }
        {
          !disableCost && columnPreference['AMT_WITH_TAX']?.isActive &&
          <EstAmtTax groupColor={Color.cost}>
            {estimatedTaxAmount ? estimatedTaxAmount.toFixed(autoDecimal) : 0}
          </EstAmtTax>
        }

        {
          !disableCost && columnPreference['TOTAL_COST']?.isActive && <TotalCost groupColor={Color.cost}>
            {totalCost.toFixed(autoDecimal)}
          </TotalCost>
        }
        {
          !disableCost && columnPreference['TOTAL_COST_LOCAL']?.isActive && <TotalCostLocal groupColor={Color.cost}>
            {totalCostLocal.toFixed(autoDecimal)}
          </TotalCostLocal>
        }
        {!disableCost && columnPreference['BACK_TO_BACK']?.isActive && <BackToBack groupColor={Color.cost} />}
        {columnPreference['INTERNAL_REMARKS']?.isActive && <InternalRemarks>
          <InputBox
            id="remarks"
            placeholder={'Internal Remarks'}
            onBlur={onChangeInputValue}
            onChange={(e: any) => setInternalRemarks(e.target.value)}
            style={inputStyle}
            disabled={isReadOnly || !editCharge}
            defaultValue={internalRemarks}
          />
        </InternalRemarks>}
        {columnPreference['EXTERNAL_REMARKS']?.isActive && <ExternalRemarks>
          <InputBox
            id="externalRemarks"
            placeholder={'External Remarks'}
            onBlur={onChangeInputValue}
            onChange={(e: any) => setExternalRemarks(e.target.value)}
            style={inputStyle}
            disabled={isReadOnly || !editCharge}
            defaultValue={externalRemarks}
          />
        </ExternalRemarks>}
      </Overview >
      <PegModal
        isOpen={showDeleteModal}
        closeModal={onDeleteModalCancel}
        tertiaryAction={onDeleteModalConfirm}
        modalTitle={"Delete Items"}
        buttonText={"Delete"}
        modalContent={"Are you sure to delete the charge?"}
      />

      <div>
        <PegModal
          isOpen={showTariffModal}
          closeModal={onTariffModalCancel}
          tertiaryAction={onTariffModalConfirm}
          disableHeader={true}
          isCenter={true}
          modalTitle={"Confirm"}
          tertiaryMsg={"No"}
          buttonText={"Yes"}
          showTemplate={true}
        >
          <div style={{ margin: '25px' }}>{`You are about to make changes to tariffs. Please note that tariffs for one or more lines will be overridden!`}</div>
        </PegModal>
      </div>
    </OverviewHeader >
  )
};

export default Charge;
