import styled from "styled-components";

interface IStylesProps {
  isHeader?: boolean;
  isService?: boolean;
  isSticky?: boolean;
  groupColor?: any;
  colour?: any;
  showBorder?: boolean;
  value?: any;
  isDropdown?: boolean;
  isDate?: boolean;
}

export const Rows = styled.div<IStylesProps>`
display: flex;
font-size: 12px;
justify-content: center;
align-items: center;
min-height: 36px;
max-height: 36px;
// background-color: ${(props) => (props.isHeader ? '#C1C1C1' : props.isService ? "#F5F5F5" : '#FFF')};
border:${(props) => props.isDropdown ? '0px solid #d8d8d8' : props?.isDate ? '0px solid #d8d8d8' : '1px solid #d8d8d8'} ;
// border-right: 1px solid #d8d8d8;
`;

export const RowData = styled(Rows)`
height: ${(props) => (props.isHeader ? '36px' : 'auto')};
`;

export const Green = styled.div<IStylesProps>`
color: green;
`;
export const Red = styled.div<IStylesProps>`
color: red;
`;

export const Overview = styled.div`
  width: 75vw;
  height: 85vh;
`;
export const Heading = styled.div`
margin-left: 8px;
padding-bottom: 8px;
font-weight: bold;
font-size: 16px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  padding-right: 16px;
  border-bottom: 1px solid #C1C1C1;
`;
export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  padding-right: 16px;
  border-top: 1px solid #C1C1C1;
  span{
    color: red;
    margin-right: 12px;
    font-size: 14px;
  }
`;
export const Content = styled.div`
    height: calc(85vh - 100px);
    overflow-y: scroll;
    padding 24px;
`;
export const Row = styled.div`
    display: flex;
`;
export const Columns = styled.div`
    display: flex;
    width: 50%;
    padding: 4px 8px;
`;
export const Title = styled.div`
    display: flex;
    width: 40%;
    font-size: 14px;
    font-weight: Normal;
`;
export const Value = styled.div`
    display: flex;
    width: 70%;
    padding-left: 8px; 
    font-size: 14px;
    color: #111827;
`;

export const ChargeOverview = styled.div`
    width: 100%;
    padding: 8px 6px;
   
`;

export const ChargeHeader = styled.div`
    display: flex;
    width: 100%;
    padding: 8px 0px;
    align-items: center;
    border-bottom: 1px solid #C1C1C1;
    font-weight: bold;
    font-size: 14px;
`;

export const ChargeIcon = styled.div`
    margin-left: auto;
    padding-right: 8px;
`;

export const DetailsView = styled.div`
padding: 16px 0px;
`;
export const CustomerService = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;

export const Name = styled(RowData)`
min-width: 200px;
max-width: 200px;
z-index: 1;
position: sticky;
left: 0px;
`;
export const BillingParty = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;

//DateRange
export const StartDate = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;
export const EndDate = styled(RowData)`
  min-width: 200px;
  max-width: 200px;
`;

//Revenue
export const Quantity = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;
export const ReceivedQuantity = styled(RowData)`
  min-width: 200px;
  max-width: 200px;
`;
export const UOM = styled(RowData)`
min-width: 200px;
`;
export const UnitPrice = styled(RowData)`
  min-width: 200px;
  max-width: 200px;
`;
export const Currency = styled(RowData)`
min-width: 200px;
`;
export const Amount = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;
export const BillingCurrency = styled(RowData)`
min-width: 200px;
`;
export const ExRate = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;
export const Tax = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;
export const SalesTaxAmt = styled(RowData)`
  min-width: 200px;
  max-width: 200px;
`;
export const SalesInvoice = styled(RowData)`
min-width: 200px;
max-width: 200px;
word-break: break-all
`;
export const BillAmount = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;
export const AdjBillAmount = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;

//cost
export const Supplier = styled(RowData)`
min-width: 200px;
`;
export const EstUnitCost = styled(RowData)`
  min-width: 200px;
  max-width: 200px;
`;
export const EstCurrency = styled(RowData)`
min-width: 200px;
`;
export const EstExRate = styled(RowData)`
min-width: 200px;
`;
export const EstTaxType = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;
export const EstTax = styled(RowData)`
  min-width: 200px;
  max-width: 200px;
`;
export const EstAmt = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;
export const EstAmtTax = styled(RowData)`
min-width: 200px; 
max-width: 200px;
`;
export const CostVariance = styled(RowData)`
min-width: 200px; 
max-width: 200px; 
`;
export const BillingStatus = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;
export const BillingOptions = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;
export const PaymentTerms = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;

// sr request

export const SrNo = styled(RowData)`
min-width: 200px;
`;
export const SrStatus = styled(RowData)`
min-width: 200px;
`;
export const SrRating = styled(RowData)`
min-width: 100px;
`;
export const ServiceStatus = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;
export const SrStartTime = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;
export const SrEndTime = styled(RowData)`
min-width: 200x;
max-width: 200px;
`;
export const SupplierInvoice = styled(RowData)`
min-width: 200x;
max-width: 200px;
`;

// operating income 
export const OpEstimated = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;
export const OpActuals = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;
export const OperatingIncome = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;


// eye_share
export const EsRefNo = styled(RowData)`
min-width: 200px;
max-width: 2000px;
`;

export const SupplierInvoiceDetail = styled(RowData)`
min-width: 200px;
max-width: 200px;
display: flex;
flex-wrap: wrap;
height: auto;
`;

export const EsActualCost = styled(RowData)`
min-width: 200px;
height: auto;
`;

//credit_note
export const CreditNote = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;
export const CreditAmt = styled(RowData)`
min-width: 200px;
max-width: 200px;
`;
export const CreditNoteRef = styled(RowData)`
min-width: 200px;
max-width: 200px;
display: flex;
flex-wrap: wrap;
height: auto;
`;

// cash voucher
export const IsCashVoucherNeeded = styled(RowData)`
  min-width: 150px;
  max-width: 150px;
`;

export const VoucherNo = styled(RowData)`
min-width: 200px;
max-width: 200px;
display: flex;

a {
  padding 0px 8px;
}
span {
  margin-left: auto;
  padding 0px 8px;
}
`;


// remarks
export const InternalRemarks = styled(RowData)`
min-width: 200px;
`;

export const ExternalRemarks = styled(RowData)`
min-width: 200px;
`;

export const EstExTax = styled(RowData)`
min-width: 200px;
`;

export const LocalAmount = styled(RowData)`
min-width: 100px;
max-width: 100px;
`;
export const EstQuantity = styled(RowData)`
min-width: 100px;
max-width: 100px;
`;
export const EstUOM = styled(RowData)`
min-width: 200px;
`;

export const EstPurchaseTax = styled(RowData)`
min-width: 150px;
`;
export const SrSerStatus = styled(RowData)`
min-width: 150px;
`;
export const SrEstimated = styled(RowData)`
min-width: 150px;
`;
export const SrActuals = styled(RowData)`
min-width: 100px;
max-width: 100px;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis
`;
export const SrReferenceNo = styled(RowData)`
min-width: 150px;
`;
export const SrActualCost = styled(RowData)`
min-width: 150px;
`;
export const SrCost = styled(RowData)`
min-width: 150px;
`;
export const BackToBack = styled(RowData)`
min-width: 150px;
max-width: 150px;
`;
export const IsBillable = styled(RowData)`
min-width: 150px;
max-width: 150px;
`;

export const TariffOverview = styled.div`
    width: 100%;
    padding: 8px 6px;
   
`;

export const TariffHeader = styled.div`
    display: flex;
    width: 100%;
    padding: 8px 0px;
    align-items: center;
    border-bottom: 1px solid #C1C1C1;
    font-weight: bold;
    font-size: 14px;
`;

export const TariffIcon = styled.div`
    margin-left: auto;
    padding-right: 8px;
`;
