import styled from "styled-components";

export const Overview = styled.div`
  padding: 36px;
`
export const Section = styled.div`
  padding: 12px 0px;
`
export const ButtonActions = styled.div`
  margin: 12px 0px;
`

export const Flex = styled.div`
  display: flex;
`
export const ContainerQuantity = styled.div`
  width: 200px;
  padding: 4px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const ContainerWeight = styled.div`
  width: 200px;
  padding: 4px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const ContainerType = styled.div`
  width: 200px;
  padding: 4px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const Delete = styled.div`
  padding: 4px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const CargoList = styled.div`
  padding: 12px 0px;
`
export const BulkType = styled.div`
  width: 200px;
  padding: 4px 8px;
`
export const BulkOperation = styled.div`
  width: 200px;
  padding: 4px 8px;
`
export const BulkWeight = styled.div`
  width: 200px;
  padding: 4px 8px;
`
export const Id = styled.div`
  width: 30px;
  padding: 4px 8px;
`
export const Quantity = styled.div`
  width: 125px;
  padding: 4px 8px;
`
export const GrossWeight = styled.div`
  width: 120px;
  padding: 4px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const Weight = styled.div`
  width: 200px;
  padding: 4px 8px;
`
export const Length = styled.div`
  width: 125px;
  padding: 4px 8px;
`
export const Width = styled.div`
  width: 125px;
  padding: 4px 8px;
`
export const Height = styled.div`
  width: 125px;
  padding: 4px 8px;
`
export const Dimensions = styled.div`
  width: 200px;
  padding: 4px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const Volume = styled.div`
  width: 200px;
  padding: 4px 8px;
`
export const GrossVolume = styled.div`
  width: 125px;
  padding: 4px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const DeleteIcon = styled.div`
  padding: 4px 8px;
  width: 30px
`
