
import { useCallback, useEffect, useState } from 'react';
import Dropdown from 'Controls/Dropdown';
import DatePicker from 'Controls/DateRange/PegDatePicker';
import ToolTip from 'Controls/ToolTip/ToolTip';
import { ReactComponent as Expanded } from 'Assets/arrow-down.svg'
import { ReactComponent as Collapsed } from 'Assets/arrow-up.svg'
import { ReactComponent as AddNew } from 'Assets/add-new.svg'
import { ReactComponent as DeleteIcon } from 'Assets/delete-item.svg'
import { setServiceValue, setServiceDateValue, isDraftQuotation } from 'Utils/QuotationGrid';
import PegModal from 'Controls/PegModal';
import { getExchangeRates } from 'Utils/Generic';
import {
  OverviewHeader,
  Overview,
  Group,
  // list
  ID,
  Delete,
  Add,
  Expand,
  NameHeader,
  BillingParty,
  BillingPartyAddress,
  StartDate,
  EndDate,
  OperatingIncome,
  UOM,
  Quantity,
  UnitPrice,
  PriceCurrency,
  CostCurrency,
  EstTaxType,
  EstTax,
  EstAmt,
  EstAmtTax,
  UnitCost,
  TotalCost,
  TotalCostLocal,
  BackToBack,
  Supplier,
  TaxType,
  TaxRate,
  NetPrice,
  NetPriceLocal,
  NetPriceSelling,
  InternalRemarks,
  ExternalRemarks,
  CustomerService,
  Detail,
  SecondaryGroup
} from '../quote.styles'
import { VscExpandAll } from 'react-icons/vsc';
import DetailModal from 'Components/DetailModal';
import { Color } from 'Utils/Color';
import { ColumnPreferences } from 'Model/Common/ColumnPreferences';
import { QuotationCharge, Charge } from 'Model/QuotationGrid/QuotationCharge';
import { QuotationService } from 'Model/QuotationGrid/QuotationService';
import { UserDefault } from 'Model/User/UserDefault';
import { IProduct, IQBillingParties, IQuotationDetails, onUpdateChargeFunc, onUpdateServiceFunc } from 'Model/QuotationGrid/types';
import { ExchangeRateLookupEntity } from 'Model/Master/ExchangeRateLookupEntity';
import { IExchangeRate } from 'Model/Common/types';
import isNull from 'lodash/isNull';

type Props = {
  userDetails: UserDefault;
  onExpandService: () => void;
  showCharge: boolean;
  charges: QuotationCharge[];
  service: QuotationService;
  serviceIndex: number;
  serviceOptions: any;
  taxList: any;
  currencyList: any;
  uomList: any;
  billingParties: IQBillingParties[];
  quotationDetails: IQuotationDetails;
  disableRevenue: boolean;
  disableCost: boolean;
  disableOperatingIncome: boolean;
  onSelectServices?: (index: number) => void;
  selectedList?: number[];
  onChangeDropdownValue?: () => void;
  selectedProduct: IProduct;
  onUpdateCharge: onUpdateChargeFunc;
  onUpdateService: onUpdateServiceFunc;
  onDeleteService: (serviceIndex: number) => void;
  onAddCharge: (serviceIndex: number) => void;
  exchangeRates: ExchangeRateLookupEntity[];
  editCharge: boolean;
  isReadOnly: boolean;
  onBackToBackSelected: (service: any) => void;
  columnPreference: ColumnPreferences;
  onChangeService: (event: any) => void;
};

const Service = (props: Props) => {
  const {
    onExpandService,
    showCharge,
    service,
    serviceOptions,
    currencyList,
    selectedProduct,
    serviceIndex,
    onSelectServices,
    selectedList,
    onUpdateService,
    onAddCharge,
    onDeleteService,
    quotationDetails,
    disableRevenue,
    disableCost,
    disableOperatingIncome,
    exchangeRates,
    charges,
    editCharge,
    isReadOnly,
    onBackToBackSelected,
    columnPreference,
    onChangeService
  } = props

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showModal, setShowModal] = useState(false);

  const styleClass = "text-left text-xs font-medium text-gray-500 tracking-wider bg-gray-50";

  useEffect(() => {
    if (!service.StartDateTime) {
      let serviceObj = setServiceDateValue(service, 'defaultStartDateTime', selectedProduct.ProductDetails.ETA)
      onUpdateService(serviceObj, serviceIndex, selectedProduct)
    }
    if (!service.EndDateTime) {
      let serviceObj = setServiceDateValue(service, 'defaultEndDateTime', selectedProduct.ProductDetails.ETD)
      onUpdateService(serviceObj, serviceIndex, selectedProduct)
    }
  }, [selectedProduct?.ProductDetails?.ETA, selectedProduct?.ProductDetails?.ETD, service])

  useEffect(() => {
    const asyncFn = async () => {
      if (!service?.CostCurrencyCode) {
        let event = {
          name: quotationDetails?.BaseCurrencyName,
          code: quotationDetails?.BaseCurrencyCode,
          exRate: quotationDetails?.BaseCurrencyExRate
        }
        let serviceObj = setServiceValue(service, 'costCurrencyDefault', event)
        onUpdateService(serviceObj, serviceIndex, selectedProduct)
        getCostConversion()
      }
      if (!service?.PriceCurrencyCode) {
        let event = {
          name: quotationDetails?.BaseCurrencyName,
          code: quotationDetails?.BaseCurrencyCode,
          exRate: quotationDetails?.BaseCurrencyExRate
        }
        let serviceObj = setServiceValue(service, 'priceCurrencyDefault', event)
        onUpdateService(serviceObj, serviceIndex, selectedProduct)
        getPriceConversion()
        getPriceSellingConversion()
      }
    }
    asyncFn()
  }, [])

  useEffect(() => {
    const asyncFn = async () => {
      if (service?.CostCurrencyCode) {
        getCostConversion()

        if (service.BackToBack) {
          getCostConversion()
          const priceCurrencyCode = { code: service.CostCurrencyCode }
          let serviceObj = setServiceValue(service, 'priceCurrency', priceCurrencyCode)
          onUpdateService(serviceObj, serviceIndex, selectedProduct);
          getPriceConversion()
        }

      }
    }
    asyncFn()
  }, [service?.CostCurrencyCode])

  useEffect(() => {
    const asyncFn = async () => {
      if (service?.PriceCurrencyCode) {
        getPriceConversion()
        getPriceSellingConversion()
      }
    }
    asyncFn()
  }, [service?.PriceCurrencyCode])

  useEffect(() => {
    const asyncFn = async () => {
      if (service?.CostCurrencyCode) {
        getCostConversion()
      }
    }
    asyncFn()
  }, [service?.CostCurrencyCode])


  const getPriceSellingConversion = useCallback(() => {
    let serviceObj: QuotationService;
    if (service?.PriceCurrencyCode === quotationDetails?.BaseCurrencyCode) {
      serviceObj = setServiceValue(service, 'PriceSellingExRate', quotationDetails?.QuoteCurrencyExRate)
    }
    else if (quotationDetails?.QuoteCurrencyCode === quotationDetails?.BaseCurrencyCode) {
      let priceSellingExRate: any = getExchangeRates(exchangeRates, service?.PriceCurrencyCode as string, quotationDetails?.QuoteCurrencyCode)
      if (priceSellingExRate) {
        serviceObj = setServiceValue(service, 'PriceSellingExRate', priceSellingExRate.ExchangeRate);
      } else {
        serviceObj = setServiceValue(service, 'PriceSellingExRate', 0);
      }
      onUpdateService(serviceObj, serviceIndex, selectedProduct)
    } else if (quotationDetails?.QuoteCurrencyCode === service?.PriceCurrencyCode) {
      serviceObj = setServiceValue(service, 'PriceSellingExRate', 1);
      onUpdateService(serviceObj, serviceIndex, selectedProduct)
    }
    else {
      serviceObj = setServiceValue(service, 'PriceSellingExRate', 0);
      onUpdateService(serviceObj, serviceIndex, selectedProduct)
    }
  }, [service?.PriceCurrencyCode]);

  const getPriceConversion = useCallback(() => {
    let serviceObj: QuotationService;
    let priceExRate: IExchangeRate | null = null;
    let priceExRateValue: number | null = null

    if (quotationDetails?.QuoteCurrencyCode === service?.PriceCurrencyCode) {
      priceExRateValue = quotationDetails?.QuoteCurrencyExRate;
    } else {
      priceExRate = getExchangeRates(exchangeRates, service.PriceCurrencyCode!, quotationDetails?.BaseCurrencyCode)
    }

    if (priceExRate || priceExRateValue) {
      if (priceExRate?.ExchangeRate) {
        serviceObj = setServiceValue(service, 'PriceExRate', priceExRate.ExchangeRate);
      } else {
        serviceObj = setServiceValue(service, 'PriceExRate', priceExRateValue)
      }
    } else {
      serviceObj = setServiceValue(service, 'PriceExRate', 0);
    }
    onUpdateService(serviceObj, serviceIndex, selectedProduct)
  }, [service?.PriceCurrencyCode]);


  const getCostConversion = useCallback(() => {
    let serviceObj: any;
    let costExRate: IExchangeRate | null = null;
    let costExRateValue: number | null = null;

    if (quotationDetails?.QuoteCurrencyCode === service?.CostCurrencyCode) {
      costExRateValue = quotationDetails?.QuoteCurrencyExRate;
    } else {
      costExRate = getExchangeRates(exchangeRates, service?.CostCurrencyCode!, quotationDetails?.BaseCurrencyCode)
    }
    if (costExRate || costExRateValue) {
      if (costExRate?.ExchangeRate) {
        serviceObj = setServiceValue(service, 'CostExRate', costExRate.ExchangeRate);
      } else {
        serviceObj = setServiceValue(service, 'CostExRate', costExRateValue);
      }
    } else {
      serviceObj = setServiceValue(service, 'CostExRate', 0);
    }
    onUpdateService(serviceObj, serviceIndex, selectedProduct)
  }, [service?.CostCurrencyCode]);

  const onChangeDropdownValue = (event: any, id: any) => {
    let serviceObj = setServiceValue(service, id, event);
    onUpdateService(serviceObj, serviceIndex, selectedProduct);

    if (id === 'serviceName' && isNull(service.Id)) {
      onChangeService(event);
    }
  }

  const onChangeDateValue = useCallback((e: any, id: any) => {
    let serviceObj = setServiceDateValue(service, id, e)
    onUpdateService(serviceObj, serviceIndex, selectedProduct)
  }, [selectedProduct]);

  const onBackToBackToggle = (e: any) => {
    let serviceObj = setServiceValue(service, 'BackToBack', e)
    onUpdateService(serviceObj, serviceIndex, selectedProduct)
    onBackToBackSelected(serviceObj);
  }

  const onServiceDelete = useCallback(() => {
    setShowDeleteModal(true)
  }, [serviceIndex]);

  const onDeleteModalCancel = useCallback(() => {
    setShowDeleteModal(false)
  }, [serviceIndex]);

  const onDeleteModalConfirm = useCallback(() => {
    onDeleteService(serviceIndex)
    setShowDeleteModal(false)
  }, [serviceIndex]);

  const onClickCheckbox = useCallback(() => {
    !isReadOnly && onSelectServices && onSelectServices(serviceIndex)
  }, [serviceIndex]);

  const showDetailModal = useCallback(() => {
    setShowModal(true)
  }, []);

  const onCloseModal = useCallback(() => {
    setShowModal(false)
  }, []);

  // Added this here to force re-render it when values used in props are changed.
  // If put inside UI it will not trigger re-render
  const ShowSupplier = () =>
    !disableCost ? (
      <Supplier groupColor={Color.cost} isService={true}>
        <Dropdown
          defaultValue={service.SupplierName}
          id={'supplier'}
          type={'charge'}
          isMandatory={true}
          isLookup={true}
          dropdownLabel={'name'}
          dropdownValue={'name'}
          appendParams={false}
          useDebounce={true}
          style={{ backgroundColor: `${Color.cost}`, width: 199 }}
          removeBorder={true}
          removeIsClear={true}
          isMenuPortalTarget={'true'}
          domId={'stickyPosition'}
          placeHolder="Select supplier"
          disabled={!service?.Name || isReadOnly}
          url={`/mdm-search-supplier?serviceCode=${service?.Code}&companyCode=${quotationDetails.CompanyCode}&searchText=`}
          onChangeDropdown={onChangeDropdownValue}
        />
      </Supplier>
    ) : (
      <></>
    );

  return (
    <OverviewHeader className={styleClass} data-testid="quotationGridServices">
      <Overview>
        <ID isService={true}>
          <input
            type="checkbox"
            onChange={onClickCheckbox}
            checked={selectedList?.includes(serviceIndex) ?? false}
            style={!isReadOnly ?
              { cursor: 'pointer', marginRight: '8px' } : { cursor: 'not-allowed', opacity: 0.5 }} />
          {serviceIndex + 1}
        </ID>

        <ToolTip
          id="deleteToolTip"
          content={'Delete'}
          placement={'right'}
          animation={'scale-subtle'}
          arrow={true}
          duration={200}
          disabled={isReadOnly}
          delay={[75, 0]}
        >
          <Delete isService={true}>
            <DeleteIcon
              style={!isReadOnly ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: 0.5 }}
              onClick={() => !isReadOnly && onServiceDelete()}
              data-testid="deleteService"
            />
          </Delete>
        </ToolTip>

        <ToolTip
          id="addToolTip"
          content={'Add Charge'}
          placement={'right'}
          animation={'scale-subtle'}
          arrow={true}
          duration={200}
          disabled={isReadOnly}
          delay={[75, 0]}
        >
          <Add isService={true}>
            <AddNew
              style={!isReadOnly ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: 0.5 }}
              onClick={() => !isReadOnly && onAddCharge(serviceIndex)}
              data-testid="addCharges"
            />
          </Add>
        </ToolTip>

        <ToolTip
          id="expandToolTip"
          content={'Hide/Show'}
          placement={'right'}
          animation={'scale-subtle'}
          arrow={true}
          duration={200}
          disabled={isReadOnly}
          delay={[75, 0]}
        >
          <Expand
            isService={true}
            onClick={() => onExpandService()}>
            {showCharge ?
              <Collapsed style={{ cursor: 'pointer' }} />
              :
              <Expanded style={{ cursor: 'pointer' }} />
            }
          </Expand>
        </ToolTip>
        <Detail isService>
          {!isReadOnly ? <VscExpandAll
            className="icon-style"
            onClick={showDetailModal}
          /> : null}
        </Detail>
        <SecondaryGroup isSticky>
          <NameHeader isService={true}>
            <Dropdown
              defaultValue={service.Name}
              onChangeDropdown={onChangeDropdownValue}
              label={'name'}
              dropdownOptions={serviceOptions}
              dropdownLabel={'name'}
              dropdownValue={'name'}
              style={{ width: 199 }}
              placeHolder="Select service"
              dropdownMap={'services'}
              isMenuPortalTarget={'true'}
              domId={'stickyPosition'}
              removeBorder={true}
              id="serviceName"
              removeIsClear={true}
              isMandatory={true}
              disabled={!isNull(service.Id) && (service.Name || isReadOnly)}
            />
          </NameHeader>
        </SecondaryGroup>
        {columnPreference['CUSTOMER_SERVICE']?.isActive && <CustomerService isService={true} />}

        {columnPreference['BILLING_PARTY']?.isActive && <BillingParty isService={true} />}
        {columnPreference['BILLING_PARTY_ADDRESS']?.isActive && <BillingPartyAddress isService={true} />}

        {columnPreference['START_DATE']?.isActive && <StartDate isService={true}>
          <DatePicker
            value={service?.StartDateTime}
            style={{ height: 35 }}
            id={'startDate'}
            type={"dateTime"}
            timeFormat={'HH:mm'}
            format={'dd/MM/yyyy HH:mm'}
            disableBorder={true}
            enabled={!isReadOnly && editCharge}
            onChange={onChangeDateValue} />
        </StartDate>}

        {columnPreference['END_DATE']?.isActive && <EndDate isService={true}>
          <DatePicker
            value={service.EndDateTime!}
            style={{ height: 35 }}
            id={'endDate'}
            type={"dateTime"}
            timeFormat={'HH:mm'}
            disableBorder={true}
            enabled={!isReadOnly && editCharge}
            format={'dd/MM/yyyy HH:mm'}
            onChange={onChangeDateValue} />
        </EndDate>}

        {!disableOperatingIncome && columnPreference['OPERATING_INCOME']?.isActive && <OperatingIncome groupColor={Color.operatingIncome} isService={true} />}

        {!disableRevenue && <UOM groupColor={Color.revenue} isService={true} />}

        {!disableRevenue && <Quantity groupColor={Color.revenue} isService={true} />}

        {!disableRevenue && <UnitPrice groupColor={Color.revenue} isService={true} />}

        {!disableRevenue && columnPreference['PRICE_CURRENCY']?.isActive && <PriceCurrency groupColor={Color.revenue} isService={true}>
          <Dropdown
            defaultValue={service.PriceCurrencyCode}
            onChangeDropdown={onChangeDropdownValue}
            label={'code'}
            removeBorder={true}
            isLookup={false}
            dropdownOptions={currencyList}
            dropdownLabel={'code'}
            dropdownValue={'code'}
            placeHolder="Select currency"
            id="priceCurrency"
            appendParams={false}
            isMandatory={true}
            removeIsClear={true}
            isMenuPortalTarget={'true'}
            domId={'stickyPosition'}
            style={{ 'backgroundColor': `${Color.revenue}`, width: 149 }}
            disabled={isReadOnly || service.BackToBack}
          />
        </PriceCurrency>}

        {!disableRevenue && columnPreference['NET_PRICE']?.isActive && <NetPrice groupColor={Color.revenue} isService={true} />}

        {!disableRevenue && columnPreference['TAX_RATE']?.isActive && <TaxType groupColor={Color.revenue} isService={true} />}

        {!disableRevenue && columnPreference['TAX_AMT']?.isActive && <TaxRate groupColor={Color.revenue} isService={true} />}

        {!disableRevenue && columnPreference['NET_PRICE_LOCAL']?.isActive && <NetPriceLocal groupColor={Color.revenue} isService={true} />}

        {!disableRevenue && columnPreference['NET_PRICE_SELLING']?.isActive && <NetPriceSelling groupColor={Color.revenue} isService={true} />}

        <ShowSupplier />

        {!disableCost && <UnitCost groupColor={Color.cost} isService={true} />}

        {!disableCost && columnPreference['COST_CURRENCY']?.isActive && <CostCurrency groupColor={Color.cost} isService={true}>
          <Dropdown
            defaultValue={service.CostCurrencyCode}
            onChangeDropdown={onChangeDropdownValue}
            label={'code'}
            removeBorder={true}
            isLookup={false}
            dropdownOptions={currencyList}
            dropdownLabel={'code'}
            dropdownValue={'code'}
            placeHolder="Select currency"
            id="costCurrency"
            appendParams={false}
            removeIsClear={true}
            isMandatory={true}
            isMenuPortalTarget={'true'}
            domId={'stickyPosition'}
            style={{ 'backgroundColor': `${Color.cost}`, width: 149 }}
            disabled={isReadOnly}
          />
        </CostCurrency>}

        {!disableCost && columnPreference['COST_TAX_RATE']?.isActive &&
          <EstTaxType groupColor={Color.cost} isService />}
        {!disableCost && columnPreference['PURCHASE_TAX']?.isActive &&
          <EstTax groupColor={Color.cost} isService />}
        {!disableCost && columnPreference['AMT']?.isActive &&
          <EstAmt groupColor={Color.cost} isService />}
        {!disableCost && columnPreference['AMT_WITH_TAX']?.isActive &&
          <EstAmtTax groupColor={Color.cost} isService />}

        {!disableCost && columnPreference['TOTAL_COST']?.isActive && <TotalCost groupColor={Color.cost} isService={true} />}

        {!disableCost && columnPreference['TOTAL_COST_LOCAL']?.isActive && <TotalCostLocal groupColor={Color.cost} isService={true} />}

        {!disableCost && columnPreference['BACK_TO_BACK']?.isActive && <BackToBack groupColor={Color.cost} isService={true}>
          <input
            type="checkbox"
            id="BackToBack"
            disabled={isReadOnly}
            onClick={onBackToBackToggle}
            checked={service.BackToBack}
            style={{ cursor: 'pointer' }} />
        </BackToBack>
        }

        {columnPreference['INTERNAL_REMARKS']?.isActive && <InternalRemarks isService={true} />}

        {columnPreference['EXTERNAL_REMARKS']?.isActive && <ExternalRemarks isService={true} />}

      </Overview>
      <PegModal
        isOpen={showDeleteModal}
        closeModal={onDeleteModalCancel}
        tertiaryAction={onDeleteModalConfirm}
        modalTitle={"Delete Items"}
        buttonText={"Delete"}
        modalContent={"Are you sure to delete the Service?"}
      />
      <PegModal
        isOpen={showModal}
        alertModal={true}
        isCenter={true}
        showTemplate={true}>
        <DetailModal
          onClose={onCloseModal}
          service={service}
          charge={charges}
          type={"quotation"}
        />
      </PegModal>
    </OverviewHeader>
  )
};

export default Service;
