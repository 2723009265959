
import { useCallback, useEffect, useState } from 'react';
import Dropdown from 'Controls/Dropdown';
import InputBox from 'Controls/Input/PegInputBox';
import {
  ChargeOverview,
  ChargeHeader,
  ChargeIcon,
  Row,
  Columns,
  Title,
  Value,
  DetailsView,
  Name,
  CustomerService,
  Quantity,
  ReceivedQuantity,
  UOM,
  OperatingIncome,
  Red,
  Green,
  BillingParty,
  UnitPrice,
  Currency,
  Amount,
  BillingCurrency,
  ExRate,
  Tax,
  SalesTaxAmt,
  BillAmount,
  AdjBillAmount,
  EstUnitCost,
  EstCurrency,
  EstExRate,
  EstTaxType,
  EstTax,
  EstAmtTax,
  CreditAmt,
  EsActualCost,
  BillingStatus,
  SalesInvoice,
  InternalRemarks,
  ExternalRemarks
} from './details.styles';
import { convertPricesAndTaxes, setChargeValue, setServiceValue, setTariffDetails } from 'Utils/JobGrid';
import { BILLING_STATUS_OPTIONS_LIST, CHARGE, SERVICE } from 'Constants/Constant';
import axios from 'axios';
import { checkforNonNegativeValue } from 'Utils/Generic'
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import Tariff from './TariffBreakDown'
import { JOB_COLUMN } from 'Constants/ColumnFilterConstants';

type Props = {
  charge?: any;
  service?: any;
  services?: any;
  type?: string;
  columnPreference?: any;
  charges?: any;
  jobDetails: any;
  serviceIndex: number;
  chargeIndex: number;
  uomList: any[];
  serviceCode?: string;
  billingParties: any[];
  currencyList: any[];
  supplierList?: any[];
  disableCost?: boolean;
  disableServiceRequest?: boolean;
  disableOperatingIncome?: boolean;
  disableRevenue?: boolean;
  disableBilling?: boolean;
  isSupplierInvoiceCNGenerated?: boolean;
  taxList: any[];
  salesInvoice: any;
  userDetails: any;
  isValidParty: boolean;
  setValidParty: any;
  serviceObj?: any;
  setServiceObj?: any;
  onChangeDropdown: (type: string, elemId: any, value: any, serviceIndex: any, chargeIndex: number) => void;
  onChangeInput: (type: string, event: any, id: any, serviceIndex: any, chargeIndex: any) => void;
  onUpdateCharge: (charge: any, serviceIndex: number, chargeIndex: number) => void;
  onUpdateService: (service: any, serviceIndex: any) => void;
};

const ChargeLine = (props: Props) => {
  const {
    service,
    services,
    charge,
    serviceIndex,
    chargeIndex,
    disableCost,
    disableServiceRequest,
    disableOperatingIncome,
    disableRevenue,
    disableBilling,
    uomList,
    taxList,
    billingParties,
    jobDetails,
    onUpdateCharge,
    salesInvoice,
    userDetails,
    setValidParty,
    onUpdateService,
    columnPreference,
    onChangeDropdown,
    onChangeInput,
    serviceObj,
    setServiceObj,
    isSupplierInvoiceCNGenerated,
    charges
  } = props;
  const {
    CurrencyDecimalPlaces,
    CurrencyDecimalPlacesAutoCalculation
  } = userDetails
  const [showDetails, setShowDetails] = useState(false);
  const [customerService, setCustomerService] = useState([])
  const [chargeList, setChargeList] = useState([])

  let decimalPlace = CurrencyDecimalPlaces ? parseInt(CurrencyDecimalPlaces) : 2;
  let autoDecimal = CurrencyDecimalPlacesAutoCalculation ? parseInt(CurrencyDecimalPlacesAutoCalculation) : 2;
  let isDisabled = BILLING_STATUS_OPTIONS_LIST.includes(service.BillingStatus)

  useEffect(() => {
    const asyncFn = async () => {
      if (service?.PriceCurrencyCode !== charge?.PriceCurrencyCode) {
        let chargeObj = setChargeValue(charge, jobDetails, 'PriceCurrencyCode', service?.PriceCurrencyCode)
        setChargeValue(charge, jobDetails, 'PriceExchangeRate', service?.PriceExchangeRate)
        charges[chargeIndex] = chargeObj;
        let serviceValue = {
          ...serviceObj,
          charges: [...charges]
        }
        setServiceObj(serviceValue)
      }
    }
    asyncFn()
  }, [service?.PriceExchangeRate, service?.PriceCurrencyCode])

  useEffect(() => {
    if (service?.Code && !charge?.Code) {
      axios.get(`/getCharges?company-code=${jobDetails.CompanyCode}&service-code=${service?.Code}&includeDolphinMapping=true&partyCode=${jobDetails.CustomerCode}`).then((response) => {
        setChargeList(response.data)
      })
    }
  }, [service?.Code])

  useEffect(() => {
    const asyncFn = async () => {
      if (service?.CostExchangeRate || service?.CostExchangeRate === 0 || service?.CostCurrencyCode !== charge?.CostCurrencyCode) {
        let chargeObj = setChargeValue(charge, jobDetails, 'CostCurrencyCode', service?.CostCurrencyCode)
        chargeObj = setChargeValue(chargeObj, jobDetails, 'CostExchangeRate', service?.CostExchangeRate)
        charges[chargeIndex] = chargeObj;
        let serviceValue = {
          ...serviceObj,
          charges: [...charges]
        }
        setServiceObj(serviceValue)
      }
    }
    asyncFn()
  }, [service?.CostExchangeRate, service?.CostCurrencyCode])

  useEffect(() => {
    const asyncFn = async () => {
      if (service?.BillingExchangeRate || service?.BillingExchangeRate === 0 || service?.BillingCurrencyCode !== charge?.BillingCurrencyCode) {
        let chargeObj = setChargeValue(charge, jobDetails, 'BillingCurrencyCode', service?.BillingCurrencyCode)
        chargeObj = setChargeValue(chargeObj, jobDetails, 'BillingExchangeRate', service?.BillingExchangeRate)
        charges[chargeIndex] = chargeObj;
        let serviceValue = {
          ...serviceObj,
          charges: [...charges]
        }
        setServiceObj(serviceValue)
      }
    }
    asyncFn()
  }, [service?.BillingExchangeRate, service?.BillingCurrencyCode])

  useEffect(() => {
    const asyncFn = async () => {
      if (billingParties && billingParties.length === 1) {
        let chargeObj = setChargeValue(charge, jobDetails, 'billingParty', billingParties[0])
        charges[chargeIndex] = chargeObj;
        let serviceValue = {
          ...serviceObj,
          charges: [...charges]
        }
        setServiceObj(serviceValue)
      }
    }
    asyncFn()
  }, [billingParties])

  useEffect(() => {
    const asyncFn = async () => {
      if (billingParties && billingParties.length === 1) {
        getVatTaxDetails(billingParties[0])
      }
    }
    asyncFn()
  }, [charge.Name])

  useEffect(() => {
    if (chargeList?.length > 0 && charge?.Name) {
      let selectedCharge: any = chargeList?.find((item: any) => charge.Name === item.name)
      setCustomerService(selectedCharge?.GSServices)
    }
  }, [chargeList, charge.Name])

  useEffect(() => {

    if (service?.BackToBack) {

      const supplierAmount = charge?.TotalEyeshareAmount ?? 0
      const isSupplierAmountValid = service?.supplierDetails?.supplierInvoice?.[0] || supplierAmount > 0

      const quantityObj = { target: { id: 'quantity', type: 'number', value: 1 } }
      let chargeObj = setChargeValue(charge, jobDetails, 'quantity', quantityObj)

      let unitPrice = isSupplierAmountValid ? supplierAmount : charge?.UnitCost
      chargeObj = setChargeValue(chargeObj, jobDetails, 'unitPrice', unitPrice)

      onUpdateCharge(chargeObj, serviceIndex, chargeIndex)

    }

  }, [charge.TotalEyeshareAmount])

  const styleClass = "text-left text-xs font-medium text-gray-500 tracking-wider bg-gray-50";
  const inputStyle = {
    height: '34px',
    borderRadius: '0px',
    fontSize: '12px',
    border: 'none',
  }

  const {
    operatingIncome,
    estimatedTaxAmount,
    purchaseTaxAmount,
    billAmount,
    adjBillAmount,
    billTax,
    amountValue,
  } = convertPricesAndTaxes(jobDetails.CurrencyCode, services[serviceIndex], chargeIndex);

  let Obj = setChargeValue(charge, jobDetails, 'OperatingIncomeActual', operatingIncome);

  useEffect(() => {
    const asyncFn = async () => {
      if (Obj.OperatingIncomeActual) {
        charges[chargeIndex] = Obj;
        let serviceValue = {
          ...serviceObj,
          charges: [...charges]
        }
        setServiceObj(serviceValue)
      }
    }
    asyncFn()
  }, [Obj])

  const getVatTaxDetails = (event: any) => {
    if (charge?.MasterCode) {
      axios.post(`/jobService-mapVatTaxDetails?operationalProcessFk=${event?.jobOperationalProcessFk}
      &billingPartyCode=${event?.code}
      &companyCode=${jobDetails?.CompanyCode}
      &gacMasterCode=${charge?.MasterCode}`).then((response) => {
        if (response?.data) {
          let chargeObj = setChargeValue(charge, jobDetails, 'vatType', response.data?.response?.data[0]?.vatType)
          charges[chargeIndex] = chargeObj;
          let serviceValue = {
            ...serviceObj,
            charges: [...charges]
          }
          setServiceObj(serviceValue)
        }
      })
    }
  }

  const onChangeInputValue = useCallback((event: any) => {
    if (event.target.type === 'number') {
      var t = event.target.value;
      event.target.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), decimalPlace + 1)) : t;
    }
    onChangeInput('charge', event.target?.id, event, serviceIndex, chargeIndex);
  }, [charge])

  const onChangeDropdownValue = useCallback((event: any, id: any) => {
    const {
      onUpdateService
    } = props
    if (id === 'billingParty') {
      setValidParty(true);
      if (charge.Name && charge?.MasterCode) {
        getVatTaxDetails(event)
      }
    }
    if (id === 'customerService') {
      let servObj = setServiceValue(service, jobDetails, id, event);
      onUpdateService(servObj, serviceIndex)
    }
    onChangeDropdown('charge', id, event, serviceIndex, chargeIndex)

    if (id === 'chargeName') {
      chargeNameCascadedUpdate(event, charge)
    }

  }, [charge])

  const chargeNameCascadedUpdate = (event: any, charge: any) => {
    let uomDefault: string | null = null;
    let quantityDefault: number | null = null;

    if (event.code == CHARGE.AIR_FREIGHT_FEE && service.Code == SERVICE.AIRFREIGHT) {
      uomDefault = jobDetails.AirFreightUOM;
      quantityDefault = jobDetails.AirFreightQuantity;
    }
    else if (event.code == CHARGE.SEA_FREIGHT_FEE && service.Code == SERVICE.SEAFREIGHT) {
      uomDefault = jobDetails.SeaFreightUOM;
      quantityDefault = jobDetails.SeaFreightQuantity;
    }

    if (uomDefault) {
      let uom = uomList.find(uom => uom.code === uomDefault);
      if (uom) {
        onChangeDropdownValue(uom, 'UOM')
      }
    }
    if (quantityDefault) {
      onChangeInputValue({ target: { id: 'quantity', type: 'number', value: quantityDefault.toString() } });
    }
  }

  const onClickDropdown = useCallback((type: string) => {
    if (type === 'charge') {
      if (!chargeList.length) {
        getCharges()
      }
    }
  }, [chargeList])

  const getCharges = useCallback(() => {
    axios.get(`/getCharges?company-code=${jobDetails.CompanyCode}&service-code=${service?.Code}&includeDolphinMapping=true&partyCode=${jobDetails.CustomerCode}`).then((response) => {
      setChargeList(response.data)
    })
  }, [charge])

  const forceUnitPriceValue = (event: any) => {
    const val = parseFloat(event.target.value);
    const min = service?.IsBillable ? 1 : 0
    if (val < min) {
      event.target.value = ''
    }
  }

  const salesInvoiceFilter = salesInvoice && salesInvoice.filter((invoice: any) => invoice.jobServiceId === charge.Id);
  return (
    <ChargeOverview>
      <ChargeHeader onClick={() => setShowDetails(!showDetails)}>
        {charge?.Name}
        <ChargeIcon>
          {
            showDetails ?
              <AiOutlineUp
                style={{ cursor: 'pointer', color: '#000' }} />
              :
              <AiOutlineDown
                style={{ cursor: 'pointer', color: '#000' }} />
          }
        </ChargeIcon>
      </ChargeHeader>
      {
        showDetails &&
        <DetailsView>
          <Row>
            <Columns>
              <Title>
                {JOB_COLUMN.NAME.label}
              </Title>
              <Value>
                <Name data-testid="chargeName" isDropdown>
                  <Dropdown
                    defaultValue={charge.Name}
                    postFetch={true}
                    id="chargeName"
                    dropdownOptions={chargeList}
                    onClickDropdown={(type: string) => onClickDropdown('charge')}
                    onChangeDropdown={onChangeDropdownValue}
                    label={'name'}
                    isLookup={false}
                    dropdownLabel={'name'}
                    dropdownValue={'name'}
                    style={{ width: 200 }}
                    placeHolder="Select Charge"
                    appendParams={false}
                    isMandatory={true}
                    removeIsClear={true}
                    isMenuPortalTarget={'true'}
                    domId={'jobChargeView'}
                    disabled={charge.IsFromMaster || isDisabled || isSupplierInvoiceCNGenerated}
                    dataTestId={'chargeName'}
                  />
                </Name>
              </Value>
            </Columns>
            <Columns>
              <Title>
                {JOB_COLUMN.CUSTOMER_SERVICE.label}
              </Title>
              <Value>
                <CustomerService isDropdown>
                  {
                    charge.Name ?
                      <Dropdown
                        defaultValue={charge.GSServiceName}
                        id={'customerService'}
                        dropdownOptions={customerService}
                        dropdownLabel={'name'}
                        dropdownValue={'name'}
                        appendParams={false}
                        useDebounce={true}
                        style={{ width: 200 }}
                        removeIsClear={true}
                        placeHolder="Select customer"
                        onChangeDropdown={onChangeDropdownValue}
                        isMenuPortalTarget={'true'}
                        type="charge"
                        domId={'stickyPosition'}
                        disabled={isDisabled}
                      />
                      :
                      <Dropdown
                        defaultValue={charge.GSServiceName}
                        id={'customerService'}
                        dropdownOptions={customerService}
                        dropdownLabel={'GSServiceName'}
                        dropdownValue={'GSServiceName'}
                        isLookup={true}
                        appendParams={false}
                        useDebounce={true}
                        style={{ width: 198 }}
                        placeHolder="Select customer"
                        onChangeDropdown={onChangeDropdownValue}
                        isMenuPortalTarget={'true'}
                        removeIsClear={true}
                        type="charge"
                        url={`/oum-getGacShipServices?partyCode=${jobDetails?.CustomerCode}&serviceCode=${service?.Code}&chargeCode=${charge?.Code}&companyCode=${jobDetails.CompanyCode}&searchText=`}
                        domId={'stickyPosition'}
                        disabled={isDisabled}
                      />
                  }
                </CustomerService>
              </Value>
            </Columns>
          </Row>
          <Row>
            <Columns>
              <Title>
                {JOB_COLUMN.QUANTITY.label}
              </Title>
              <Value>
                <Quantity>
                  <InputBox
                    value={charge.Quantity}
                    type={'number'}
                    placeholder={'Enter Qty'}
                    onChange={onChangeInputValue}
                    id="quantity"
                    isMandatory={true}
                    style={inputStyle}
                    onInput={(event: any) => {
                      checkforNonNegativeValue(event);
                    }}
                    disabled={charge.IsFromMaster || isDisabled || service.BackToBack}
                    min={"0"}
                  />
                </Quantity>
              </Value>
            </Columns>
            <Columns>
              <Title>
                {JOB_COLUMN.RECIEVED_QTY.label}
              </Title>
              <Value>
                <ReceivedQuantity>
                  {charge && charge.ReceivedQuantity}
                </ReceivedQuantity>
              </Value>
            </Columns>
          </Row>
          <Row>
            <Columns>
              <Title>
                {JOB_COLUMN.UOM.label}
              </Title>
              <Value>
                <UOM data-test="uom" isDropdown>
                  <Dropdown
                    id="UOM"
                    defaultValue={charge.UomCode}
                    type={'charge'}
                    removeIsClear={true}
                    label={'code'}
                    dropdownOptions={uomList}
                    isLookup={false}
                    dropdownLabel={'displayName'}
                    dropdownValue={'code'}
                    appendParams={false}
                    style={{ width: 200 }}
                    placeHolder="Select UOM"
                    isMandatory={true}
                    onChangeDropdown={onChangeDropdownValue}
                    isMenuPortalTarget={'true'}
                    domId={'jobChargeView'}
                    disabled={charge.IsFromMaster || isDisabled || isSupplierInvoiceCNGenerated}
                    dataTestId={'uom'}
                  />
                </UOM>
              </Value>
            </Columns>
            {/* {!disableOperatingIncome && columnPreference['OPERATING_INCOME']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.OPERATING_INCOME.label}
              </Title>
              <Value>
                <OperatingIncome>
                  {operatingIncome < 0 ?
                    <Red>
                      {Math.abs(operatingIncome).toFixed(autoDecimal)}
                    </Red>
                    :
                    <Green>
                      {operatingIncome.toFixed(autoDecimal)}
                    </Green>}
                </OperatingIncome>
              </Value>
            </Columns>
            {/* } */}
          </Row>

          <Row>
            {/* {
              !disableRevenue && columnPreference['BILLING_PARTY']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.BILLING_PARTY.label}
              </Title>
              <Value>
                <BillingParty data-testid="billingParty" isDropdown>
                  <Dropdown
                    defaultValue={charge.OperationalProcessPartyFk}
                    id={'billingParty'}
                    type={'charge'}
                    dropdownOptions={billingParties}
                    dropdownLabel={'legalName'}
                    dropdownValue={'operationalProcessPartyId'}
                    appendParams={false}
                    useDebounce={true}
                    style={{ width: 200 }}
                    isMandatory={true}
                    placeHolder="Select party"
                    onChangeDropdown={onChangeDropdownValue}
                    isMenuPortalTarget={'true'}
                    domId={'jobChargeView'}
                    removeIsClear={true}
                    disabled={isDisabled}
                    dataTestId={'billingParty'}
                  />
                </BillingParty>
              </Value>
            </Columns>
            {/* } */}
            {/* {
              !disableRevenue && columnPreference['UNIT_PRICE']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.UNIT_PRICE.label}
              </Title>
              <Value>
                <UnitPrice data-testid="unitPrice">
                  <InputBox
                    id="unit"
                    value={charge.UnitPrice}
                    type={'number'}
                    step='any'
                    isMandatory={true}
                    checkZeroValue={!service?.IsBillable}
                    nonNegativeNumber={true}
                    nonZeroNumber={service?.IsBillable}
                    placeholder={'Enter Unit Price'}
                    onChange={onChangeInputValue}
                    style={inputStyle}
                    onInput={(event: any) => {
                      forceUnitPriceValue(event);
                    }}
                    validator={(value) => value >= (service?.IsBillable ? 1 : 0)}
                    disabled={charge.IsFromMaster || isDisabled || service.BackToBack}
                  />
                </UnitPrice>
              </Value>
            </Columns>
            {/* } */}
          </Row>
          <Row>
            {/* {!disableRevenue && columnPreference['CURRENCY']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.CURRENCY.label}
              </Title>
              <Value>
                {

                  <Currency>
                    {charge?.PriceCurrencyCode}
                  </Currency>
                }
              </Value>
            </Columns>
            {/* } */}
            {/* {!disableRevenue && columnPreference['AMOUNT']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.AMOUNT.label}
              </Title>
              <Value>
                <Amount>
                  {amountValue ? amountValue.toFixed(autoDecimal) : 0}
                </Amount>

              </Value>
            </Columns>
            {/* } */}
          </Row>

          <Row>
            {/* {
              !disableRevenue && columnPreference['BILLING_CURRENCY']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.BILLING_CURRENCY.label}
              </Title>
              <Value>
                <BillingCurrency>
                  {charge?.BillingCurrencyCode}
                </BillingCurrency>
              </Value>
            </Columns>
            {/* } */}
            {/* {!disableRevenue && columnPreference['EX_RATE']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.EX_RATE.label}
              </Title>
              <Value>
                <ExRate>
                  {charge?.BillingExchangeRate}
                </ExRate>
              </Value>
            </Columns>
            {/* } */}
          </Row>

          <Row>
            {/* {!disableRevenue && columnPreference['TAX_RATE']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.TAX_RATE.label}
              </Title>
              <Value>
                <Tax isDropdown>
                  <Dropdown
                    defaultValue={charge.PriceTaxCode}
                    id="actualTax"
                    type={'charge'}
                    label={'Name'}
                    isLookup={false}
                    dropdownOptions={taxList}
                    dropdownLabel={'Name'}
                    dropdownValue={'Code'}
                    appendParams={false}
                    style={{ width: 200 }}
                    placeHolder="Select Tax"
                    onChangeDropdown={onChangeDropdownValue}
                    isMenuPortalTarget={'true'}
                    domId={'jobChargeView'}
                    disabled={isDisabled}
                  />
                </Tax>
              </Value>
            </Columns>
            {/* } */}
            {/* {
              !disableRevenue && columnPreference['SALES_TAX_AMT']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.SALES_TAX_AMT.label}
              </Title>
              <Value>
                <SalesTaxAmt>
                  {billTax.toFixed(autoDecimal)}
                </SalesTaxAmt>
              </Value>
            </Columns>
            {/* } */}
          </Row>
          <Row>
            {/* {
              !disableRevenue && columnPreference['BILL_AMT']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.BILL_AMT.label}
              </Title>
              <Value>
                <BillAmount>
                  {billAmount ? billAmount.toFixed(autoDecimal) : amountValue}
                </BillAmount>
              </Value>
            </Columns>
            <Columns>
              <Title>
                {JOB_COLUMN.ADJ_BILL_AMT.label}
              </Title>
              <Value>
                <AdjBillAmount>
                  {!!charge?.OffsetAmount ? adjBillAmount.toFixed(autoDecimal) : ''}
                </AdjBillAmount>
              </Value>
            </Columns>
          </Row>
          <Row>
            {/* } */}

            {/* {
              !disableCost && columnPreference['UNIT_COST']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.UNIT_COST.label}
              </Title>
              <Value>
                <EstUnitCost>
                  <InputBox
                    value={charge.UnitCost}
                    id="estUnitCost"
                    type={'number'}
                    min={0}
                    checkZeroValue={true}
                    isMandatory={true}
                    placeholder={'Enter amount'}
                    onChange={onChangeInputValue}
                    style={inputStyle}
                    onInput={(event: any) => {
                      checkforNonNegativeValue(event);
                    }}
                    disabled={charge.IsFromMaster || isDisabled || isSupplierInvoiceCNGenerated}
                  />
                </EstUnitCost>
              </Value>
            </Columns>
            {/* } */}

            {/* {
              !disableCost && columnPreference['COST_CURRENCY']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.COST_CURRENCY.label}
              </Title>
              <Value>
                <EstCurrency>
                  {charge?.CostCurrencyCode}
                </EstCurrency>
              </Value>
            </Columns>
          </Row>
          <Row>
            {/* } */}
            {/* {
              !disableCost && columnPreference['COST_EX_RATE']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.COST_EX_RATE.label}
              </Title>
              <Value>
                <EstExRate>{charge?.CostExchangeRate}</EstExRate>
              </Value>
            </Columns>
            {/* } */}

            {/* {
              !disableCost && columnPreference['COST_TAX_RATE']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.COST_TAX_RATE.label}
              </Title>
              <Value>
                <EstTaxType isDropdown>
                  <Dropdown
                    defaultValue={charge.CostTaxCode}
                    id="estimateTax"
                    type={'charge'}
                    label={'Name'}
                    isLookup={false}
                    dropdownOptions={taxList}
                    dropdownLabel={'Name'}
                    dropdownValue={'Code'}
                    appendParams={false}
                    style={{ width: 200 }}
                    placeHolder="Select Tax Type"
                    onChangeDropdown={onChangeDropdownValue}
                    isMenuPortalTarget={'true'}
                    domId={'jobChargeView'}
                    disabled={charge.IsFromMaster || isDisabled || isSupplierInvoiceCNGenerated}
                    dataTestId={'UOM'}
                  />
                </EstTaxType >
              </Value>
            </Columns>
            {/* } */}
          </Row>
          <Row>
            {/* {!disableCost && columnPreference['PURCHASE_TAX']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.PURCHASE_TAX.label}
              </Title>
              <Value>
                <EstTax>{purchaseTaxAmount.toFixed(autoDecimal)}</EstTax>
              </Value>
            </Columns>
            {/* } */}

            {/* {!disableCost && columnPreference['AMT_WITH_TAX']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.AMT.label}
              </Title>
              <Value>
                <EstAmtTax>
                  {estimatedTaxAmount.toFixed(autoDecimal)}
                </EstAmtTax>
              </Value>
            </Columns>
          </Row>
          <Row>
            {/* } */}
            {/* {
              !disableCost && columnPreference['CREDIT_AMT']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.CREDIT_AMT.label}
              </Title>
              <Value>
                <CreditAmt>
                  {charge.CreditAmount}
                </CreditAmt>
              </Value>
            </Columns>
            {/* } */}

            {/* {
              !disableCost && columnPreference['ACTUAL_COST']?.isActive && */}

            <Columns>
              <Title>
                {JOB_COLUMN.ACTUAL_COST.label}
              </Title>
              <Value>
                <EsActualCost>
                  <InputBox
                    id="eyeShareCost"
                    type={'number'}
                    disabled={charge.IsFromMaster || isDisabled || isSupplierInvoiceCNGenerated}
                    value={charge.TotalEyeshareAmount}
                    placeholder={'Enter amount'}
                    onChange={onChangeInputValue}
                    style={inputStyle}
                    onInput={(event: any) => {
                      checkforNonNegativeValue(event);
                    }}
                  />
                </EsActualCost>
              </Value>
            </Columns>
          </Row>

          <Row>
            {/* } */}
            {/* {
              !disableBilling && columnPreference['BILLING_STATUS']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.BILLING_STATUS.label}
              </Title>
              <Value>
                <BillingStatus>
                  {charge?.BillingStatus}
                </BillingStatus>
              </Value>
            </Columns>
            {/* } */}

            {/* {!disableBilling && columnPreference['SALES_INVOICE']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.SALES_INVOICE.label}
              </Title>
              <Value>
                <SalesInvoice>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {salesInvoiceFilter && salesInvoiceFilter.map(((el: any, index: any) => {
                      return (
                        <a style={{ color: '#0645AD' }}
                          href={el.InvoiceLink}
                          target="_blank" rel="noreferrer" key={index}>
                          {
                            el?.InvoiceReference ?
                              <span>
                                {el.InvoiceReference}{index === salesInvoiceFilter.length - 1 ? "" : ","}
                              </span>
                              :
                              ""
                          }

                        </a>
                      )
                    }))}
                  </div>
                </SalesInvoice>
              </Value>
            </Columns>          </Row>
          <Row>
            {/* } */}
            {/* {columnPreference['INTERNAL_REMARKS']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.INTERNAL_REMARKS.label}
              </Title>
              <Value>
                <InternalRemarks>
                  <InputBox
                    id="remarks"
                    value={charge.InternalRemarks}
                    placeholder={'Internal Remarks'}
                    onChange={onChangeInputValue}
                    style={inputStyle}
                    disabled={isDisabled}
                  />
                </InternalRemarks>
              </Value>
            </Columns>
            {/* } */}

            {/* {columnPreference['EXTERNAL_REMARKS']?.isActive && */}
            <Columns>
              <Title>
                {JOB_COLUMN.EXTERNAL_REMARKS.label}
              </Title>
              <Value>
                <ExternalRemarks>
                  <InputBox
                    id="externalRemarks"
                    value={charge.ExternalRemarks}
                    placeholder={'External Remarks'}
                    onChange={onChangeInputValue}
                    style={inputStyle}
                    disabled={isDisabled}
                  />
                </ExternalRemarks>
              </Value>

            </Columns>
          </Row>
          {
            charge?.TariffDetails?.TariffBreakdown &&
            <Tariff
              tariff={charge?.TariffDetails?.TariffBreakdown} />
          }
        </DetailsView>
      }
    </ChargeOverview>
  )
};

export default ChargeLine;
